import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  color: ${colors.utilityGray};
  padding: 16px;
  text-align: center;
`;

export const Header = styled(StyledContainer)`
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h3`
  color: ${colors.background};
  font-size: 2.4rem;
  line-height: 2.5rem;
  margin: 0;
`;

export const TabNumber = styled.span`
  color: ${colors.text};
  font-size: 6rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4px;
  margin: 16px 0;
`;

export const Message = styled.div`
  margin: 8px auto 32px;
`;

export const Buttons = styled(StyledContainer)`
  gap: 16px;

  .black-btn {
    background-color: ${colors.background};
    color: ${colors.text};

    &:disabled {
      color: inherit !important;
    }
  }

  .black-btn-outline {
    border: 1px solid ${colors.background} !important;
    color: ${colors.background};

    &:disabled {
      background-color: inherit;
      border: 1px solid ${colors.activeDisabled} !important;
      color: 1px solid ${colors.textDisabled} !important;
    }
  }
`;
