import { useCallback, useEffect, useState } from "react";

import { useTab } from "@context/TabContext";

interface ReturnProps {
  credit: string;
  hasRooamCredit: boolean;
  totalAfterCredit?: string;
  creditApplied?: string;
}

const useRooamCredit = (): ReturnProps => {
  const [totalAfterCredit, setTotalAfterCredit] = useState<
    string | undefined
  >();
  const [hasRooamCredit, setHasRooamCredit] = useState(false);
  const [creditApplied, setCreditApplied] = useState<string | undefined>();

  const { feed, receipt, tabTotal } = useTab();

  const credit = feed?.totals.credit ?? "0.00";
  const receiptTotal = receipt?.data?.receipt.total;
  const creditAppliedReceipt = receipt?.data?.receipt.creditApplied;

  const _creditToNumber = parseFloat(credit);
  const _creditAppliedReceipt = parseFloat(creditAppliedReceipt ?? "0");

  const getTotalAfterCredit = useCallback(() => {
    const _credit = parseFloat(credit);
    const _total = parseFloat(tabTotal ?? "0");

    if (receiptTotal) {
      return receiptTotal;
    } else if (_credit > _total) {
      return "0.00";
    } else {
      return (parseFloat(tabTotal ?? "0") - parseFloat(credit ?? "0")).toFixed(
        2
      );
    }
  }, [credit, receiptTotal, tabTotal]);

  useEffect(() => {
    if (_creditToNumber > 0 || _creditAppliedReceipt) {
      setHasRooamCredit(true);
    } else {
      setHasRooamCredit(false);
    }
  }, [_creditAppliedReceipt, _creditToNumber]);

  useEffect(() => {
    const _totalAfterCredit = getTotalAfterCredit();

    hasRooamCredit && setTotalAfterCredit(_totalAfterCredit);
  }, [getTotalAfterCredit, hasRooamCredit]);

  useEffect(() => {
    const creditAppliedReceiptToNumber = parseFloat(
      creditAppliedReceipt ?? "0"
    );

    if (creditAppliedReceipt) {
      creditAppliedReceiptToNumber > 0 &&
        setCreditApplied(creditAppliedReceipt);
    } else {
      const _creditToNumber = parseFloat(credit);
      const _tabTotalToNumber = parseFloat(tabTotal ?? "0");

      if (_creditToNumber === 0) {
        return setCreditApplied(undefined);
      }

      setCreditApplied(_creditToNumber > _tabTotalToNumber ? tabTotal : credit);
    }
  }, [credit, creditAppliedReceipt, tabTotal]);

  return {
    credit,
    creditApplied,
    hasRooamCredit,
    totalAfterCredit,
  };
};

export default useRooamCredit;
