import {
  PaymentCard,
  Container as PaymentMethodContainer,
  PaymentMethodItem,
  PaymentMethodLabel,
  PaymentMethodList,
} from "@component/PaymentMethodList/PaymentMethod.styles";

import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  gap: 16px;

  &.tab-overdue {
    margin: 32px 0 0;

    h3 {
      margin: 0;
    }

    ul {
      margin: 0;
    }
  }
`;

export const List = styled(PaymentMethodList)`
  margin: 32px 0 0;
`;

export const ListItem = styled(PaymentMethodItem)``;

export const Card = styled(PaymentCard)`
  border: 1px solid ${colors.border} !important;
  padding: 14px;
`;

export const CardContainer = styled(PaymentMethodContainer)`
  svg {
    fill: ${colors.success};
  }
`;

export const PaymentMethodIcon = styled.img`
  border-radius: 6px;
`;

export const Label = styled(PaymentMethodLabel)`
  font-size: 1.7rem;
  font-weight: 500;
`;

export const AddNewPaymentButton = styled(Button)`
  &.overwrite {
    margin-bottom: 32px;
  }

  div {
    align-items: center;
    display: flex;
    gap: 8px;
    width: 100%;

    .MuiSvgIcon-root {
      height: 28px;
      width: 28px;
    }
  }
`;
