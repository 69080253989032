import * as Styled from "./RooamCredit.styles";

import { useCallback, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import InfoBottomSheet from "./InfoBottomSheet";
import { colors } from "@theme/Themes";
import { useTab } from "@context/TabContext";

function RooamCredit() {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const { feed } = useTab();

  const handleInfoSheet = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);
  return (
    <Styled.Card
      data-testid="rooamCredit-module"
      className="rooam-credit"
      onClick={handleInfoSheet}
    >
      <Styled.Icon>$</Styled.Icon>
      <Styled.TextSection direction="column">
        <h4>F&B Credit</h4>
        <p>Tap for more info</p>
      </Styled.TextSection>
      <Styled.Credits>${feed?.totals.credit}</Styled.Credits>
      <BottomSheet
        id="bottom-sheet"
        className="moreMenu-sheet"
        open={isActionSheetOpen}
        onDismiss={handleInfoSheet}
        style={{
          backgroundColor: colors.text,
        }}
      >
        <InfoBottomSheet onActionSheetOpen={handleInfoSheet} />
      </BottomSheet>
    </Styled.Card>
  );
}

export default RooamCredit;
