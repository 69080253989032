import TabPaymentMethodList from "@component/TabPaymentMethodList/TabPaymentMethodList";
import { useEffect } from "react";
import usePageTitle from "@hooks/usePageTitle";

function Open() {
  usePageTitle("Connect your Payment Method");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <TabPaymentMethodList />;
}

export default Open;
