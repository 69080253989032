import { addBreadcrumb } from "@sentry/react";
import { environment } from "./constants";

async function openTab(placeCode: string) {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  const body = JSON.stringify({
    placeCode,
  });

  try {
    addBreadcrumb({
      level: "info",
      category: "user_action",
      message: `Opening Tab at Place ${placeCode}`,
    });

    const response = await fetch(`${environment.baseUrl}/tab`, {
      method: "POST",
      headers,
      body,
    });

    if (!response.ok) {
      console.warn(`openTab > Request failed with status ${response.status}`);
      return;
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
}

export default openTab;
