import { IconContainer } from "./VendorIcon.styles";
import { IconFillColorProps } from "@component/Icons/Icon";
import RewardsIcon from "../../assets/rewards.png";

export default function VendorRewardsIcon(
  props: IconFillColorProps
): React.ReactElement {
  return (
    <IconContainer>
      <img width={42} src={RewardsIcon} alt="Tao Group Rewards icon" />
    </IconContainer>
  );
}
