import { addBreadcrumb } from "@sentry/react";
import { environment } from "./constants";

export interface TabUserProfile {
  id: string;
  state: string;
  provider: string;
  firstName: string;
  lastName: string;
  birthdateTimestamp: number;
  faceImageUrl: string;
  ageVerified: boolean;
}

async function getTabUserProfile(): Promise<TabUserProfile | undefined> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  try {
    addBreadcrumb({
      level: "info",
      category: "user_action",
      message: "Getting WebTab user profile",
    });

    const response = await fetch(
      `${environment.baseUrl}/identity/verification/profile`,
      {
        method: "GET",
        headers,
      }
    );

    if (!response.ok) {
      throw new Error(
        `getTabUserProfile > Request failed getting User Identity Verification Profile ${response.status}`
      );
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
}

export default getTabUserProfile;
