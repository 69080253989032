import { colors, fonts } from "@theme/Themes";

import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  gap: 12px;
  margin: 32px 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    font-size: 1.8rem;
    font-family: ${fonts.textFont};
  }

  input {
    font-family: ${fonts.textFont};
    font-size: 1.8rem;

    &[type="submit"] {
      background-color: ${colors.text};
      border: none;
      border-radius: 6px;
      color: ${colors.background};
      font-weight: 600;
      padding: 14px;
      margin-top: 8px;

      &:hover {
        background-color: ${colors.text};
        color: ${colors.background};
      }
    }
  }
`;

export const FormRow = styled(StyledContainer)`
  input {
    border-radius: 6px;
    border: none;
    padding: 14px;
  }
`;
