import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  .order-list {
    .MuiPaper-root {
      border: 1px solid ${colors.border};
      font-size: 1.6rem;
      line-height: 1.8rem;

      .item-unit-price {
        font-size: 1.5rem;
      }
    }
  }

  button {
    &:not(.rooam-credit) div {
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 8px;
      width: 100%;

      .MuiSvgIcon-root {
        height: 20px;
        width: 20px;
      }
    }
  }
`;

export const Title = styled.h1`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 6rem;
  line-height: 1;
  justify-content: center;
  margin-bottom: 47px;

  strong {
    font-weight: 600;
    line-height: 1;
    letter-spacing: 4px;
    margin: 8px 0 0;
  }

  span {
    color: ${colors.activeDisabled};
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const OpenTabDisclaimer = styled.span`
  color: ${colors.utilityGray};
  text-align: center;
  font-size: 1.4rem;
  line-height: 20px;
  margin-top: 18px;
`;

export const ButtonContainer = styled(StyledContainer)``;
