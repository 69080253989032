import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  align-items: center;
  flex: 100% 1 1;
  justify-content: center;
  padding: 32px;
  gap: 16px;
`;

export const ContentContainer = styled(StyledContainer)`
  align-items: center;
  justify-content: center;
  margin: auto 0;
`;

export const Title = styled.h3`
  margin: 32px 0 0;
`;

export const OpenTabButton = styled(Button)`
  &.overwrite {
    display: flex;
    margin-top: auto;
  }
`;
