import BlockIcon from "@mui/icons-material/Block";
import { colors } from "@theme/Themes";
import styled from "styled-components";

const CircleContainer = styled.div`
  background-color: ${colors.borderMedium};
  border: 10px solid ${colors.border};
  border-radius: 50%;
  height: 96px;
  width: 96px;
  position: relative;

  svg {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

function VoidedIcon() {
  return (
    <CircleContainer className="icon-container">
      <BlockIcon />
    </CircleContainer>
  );
}

export default VoidedIcon;
