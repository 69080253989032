import { LoaderContainer, StepContainer } from "./IdentityVerification.styles";

import ComponentLoader from "@component/Loading/ComponentLoader";
import incode from "incode";
import { useEffect } from "react";

interface ProcessIdProps {
  session: any;
  onSuccess: () => void;
}

function ProcessId({ session, onSuccess }: ProcessIdProps) {
  useEffect(() => {
    if (incode) {
      incode
        .processId({ token: session.token })
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          console.error(error);
          throw new Error("Identity Verification Error: Error Processing Id.");
        });
    }
  }, [onSuccess, session]);

  if (!incode) {
    return null;
  }

  return (
    <StepContainer>
      <LoaderContainer>
        <ComponentLoader loaderText="Processing ..." />
      </LoaderContainer>
    </StepContainer>
  );
}

export default ProcessId;
