import { ResetPermissionContainer } from "./IdentityVerification.styles";
import SecurityIcon from "@mui/icons-material/Security";

function ResetPermissions() {
  return (
    <ResetPermissionContainer direction="column">
      <h1>Reset Camera Permission</h1>
      <SecurityIcon fontSize="large" />
      <p>
        To continue with the onboarding process, we will need{" "}
        <strong>your permission for camera use</strong>.
      </p>
    </ResetPermissionContainer>
  );
}

export default ResetPermissions;
