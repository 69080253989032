import {
  CheckIconContainer,
  CheckIconMainContainer,
} from "@component/TabPaid/TabPaid.styles";
import { useCallback, useEffect, useState } from "react";

import { ReactComponent as CheckIcon } from "@vendor/assets/check-icon.svg";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { VerificationStatus } from "./Steps";
import { Zoom } from "@mui/material";
import { colors } from "@theme/Themes";
import incode from "incode";
import styled from "styled-components";
import updateTabIdentityProfile from "@utils/updateTabIdentityProfile";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

const Container = styled(StyledContainer)`
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    color: ${colors.background};
  }
`;

function OnboardingComplete({ session }: { session: any }) {
  const [ocrData, setOcrData] = useState<any>(undefined);
  const [selfie, setSelfie] = useState<string | undefined>(undefined);

  const navigate = useNavigate();
  const { placeCode } = useTab();

  const handleOcrData = useCallback(async () => {
    try {
      const result = incode && await incode.ocrData({ token: session.token });

      setOcrData(result);
    } catch (error) {
      console.error("An error occurred getting OCR Data from Incode", error);
    }
  }, [session]);

  const handleSelfieImg = useCallback(async () => {
    try {
      const result = incode && await incode.getImages({
        token: session?.token,
        body: { images: ["croppedFace"] },
      });

      setSelfie(result?.croppedFace);
    } catch (error) {
      console.error("An error occurred getting OCR Data from Incode", error);
    }
  }, [session]);

  useEffect(() => {
    void handleOcrData();
    void handleSelfieImg();
  }, [handleOcrData, handleSelfieImg, session]);

  const handleUpdateIdentityProfile = useCallback(async () => {
    try {
      const response = await updateTabIdentityProfile({
        id: session?.interviewId,
        state: VerificationStatus.VERIFIED,
        ocrData,
        base64FaceImage: selfie ?? "",
      });

      if (!response) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      return navigate(`/venue/${placeCode}/${response}`, {
        replace: true,
      });
    } catch (error) {
      console.error("An error occurred updating the identity profile", error);
    }
  }, [navigate, ocrData, placeCode, selfie, session?.interviewId]);

  useEffect(() => {
    if (ocrData && selfie) {
      void handleUpdateIdentityProfile();
    }
  }, [handleUpdateIdentityProfile, ocrData, selfie]);

  if (!incode) {
    return null;
  }

  return (
    <Container direction="column">
      <h1>Onboarding process Complete</h1>
      <CheckIconMainContainer className="onboardingComplete">
        <Zoom in={true}>
          <CheckIconContainer fillColor={colors.lighterBackground}>
            <CheckIcon />
          </CheckIconContainer>
        </Zoom>
      </CheckIconMainContainer>
    </Container>
  );
}

export default OnboardingComplete;
