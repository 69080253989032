import { LogoContainer } from "./VendorLogo.styles";
import RooamLogo from "@vendor/assets/logo.svg";

const VendorLogo = (): React.ReactElement => {
  return (
    <LogoContainer>
      <img src={RooamLogo} title="vendor-logo" alt="Rooam Logo" />
    </LogoContainer>
  );
};

export default VendorLogo;
