import { environment } from "./constants";

interface PaytronixData {
  amount: string;
  used: string;
}

async function applyTabPaytronixRewardPoints(
  amount: string
): Promise<PaytronixData | undefined> {
  const xRooamSession = localStorage.getItem("x-rooam-session");
  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.originUrl && headers.append("origin", environment.originUrl);
  xRooamSession && headers.append("X-Rooam-Session", xRooamSession);

  const body = JSON.stringify({
    amount,
  });

  try {
    const response = await fetch(`${environment.baseUrl}/tao`, {
      method: "POST",
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error(
        `applyTabPaytronixRewardPoints> Apply PaytronixRewardPoints request failed with status ${response.status}`
      );
    }

    const data = await response.json();

    return {
      amount: data.amount,
      used: data.used,
    };
  } catch (error) {
    console.error(error);
  }
}

export default applyTabPaytronixRewardPoints;
