import { colors } from "@theme/Themes";
import styled from "styled-components";

export const TermsCopy = styled.p`
  color: ${colors.textSecondary};
  font-size: 1.4rem;
  margin: 16px auto;
  max-width: 290px;
  text-align: center;

  a {
    color: ${colors.primary};
  }
`;
