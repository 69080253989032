import * as Styled from "./IdentityVerification.styles";

import { useCallback, useState } from "react";

import Button from "@component/Button/Button";
import IdentityVerificationImg from "@vendor/assets/images/identity-verification.png";
import Steps from "./Steps";
import incode from "incode";
import logOutTab from "@utils/logOutTab";
import { useAuth } from "@context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTab } from "@context/TabContext";

function IdentityVerification() {
  const [showIdentityVerificationSteps, setShowIdentityVerificationSteps] =
    useState(false);
  const [isLoginOut, setIsLoginOut] = useState(false);

  const { setWebTabSession, didSignOut } = useAuth();
  const navigate = useNavigate();

  const { placeCode, feedQueryKey } = useTab();

  const queryClient = useQueryClient();

  const handleIdentityVerification = useCallback(() => {
    setShowIdentityVerificationSteps(true);
  }, []);

  const handleLogOut = useCallback(async () => {
    localStorage.removeItem("x-rooam-session");

    setWebTabSession?.({
      phoneNumber: undefined,
      xRooamSession: null,
      isSessionOpen: false,
      tabData: {
        tabNumber: undefined,
        tip: undefined,
      },
      selectedPaymentMethod: undefined,
    });

    queryClient.removeQueries(feedQueryKey);

    didSignOut();

    const response = await logOutTab();

    if (response.ok) {
      return navigate(`/venue/${placeCode}/phone`, { replace: true });
    }

    setIsLoginOut(false);
  }, [
    didSignOut,
    feedQueryKey,
    navigate,
    placeCode,
    queryClient,
    setWebTabSession,
  ]);

  if (!incode) {
    return (
      <Styled.Container direction="column">
        <h3>Identity Verification Not Available</h3>
        
        <Styled.VerificationImage
          src={IdentityVerificationImg}
          alt="Verify your identity"
          width="100%"
        />
        
        <Styled.Paragraph>
          We&apos;re sorry, but the identity verification service is currently unavailable.
        </Styled.Paragraph>
         
        <Styled.SmallText>
          If you have any questions or need assistance, please contact your server.
        </Styled.SmallText>
      </Styled.Container>
    );
  }

  if (showIdentityVerificationSteps) {
    return <Steps />;
  }

  return (
    <Styled.Container direction="column">
      <h3>Identity Verification Required</h3>
      <Styled.VerificationImage
        src={IdentityVerificationImg}
        alt="Verify your identity"
        width="100%"
      />
      <Styled.Paragraph>
        Before opening a Tab at this place, we require to verify your identity.
      </Styled.Paragraph>
      <Styled.SmallText>
        This verification process is provided and operated through a secure
        identity verification provider.
      </Styled.SmallText>

      <Styled.ButtonContainer direction="column">
        <Button text="Start" onClick={handleIdentityVerification} />
        <Button
          text="Log Out"
          isLoading={isLoginOut}
          onClick={() => {
            setIsLoginOut(true);
            void handleLogOut();
          }}
          variant="outlined"
        />
      </Styled.ButtonContainer>
    </Styled.Container>
  );
}

export default IdentityVerification;
