import { colors, fonts } from "@theme/Themes";

import MaskedInput from "react-text-mask";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  color: ${colors.textSecondary};
  margin-top: 32px;
  font-size: 1.8rem;
  line-height: 2.2rem;

  h3 {
    color: ${colors.text};
    font-size: 2.4rem;
    line-height: 1;
    margin: 0 0 12px;
  }

  button {
    margin-top: 16px;
  }
`;

export const Input = styled(MaskedInput)`
  border: none;
  background-color: transparent;
  font-family: ${fonts.textFont};
  font-size: 20px;
  padding: 12px;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: ${colors.success} !important;
    background-color: transparent !important;
  }
`;

export const InputContainer = styled.div`
  align-items: center;
  background-color: ${colors.lighterBackground};
  border-radius: 6px;
  border: 2px solid ${colors.borderLight};
  display: flex;
  margin: 24px 0 0;

  span {
    border-right: 2px solid ${colors.borderLight};
    padding: 12px;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 1.4rem;
  color: ${colors.error};
  margin-top: 2px;
  padding: 0 8px;
`;

export const Help = styled(StyledContainer)`
  align-items: center;
  margin: 32px auto;
  max-width: 380px;
  text-align: center;

  p {
    font-size: 1.5rem;
    margin-bottom: 24px;
  }

  a {
    background-color: ${colors.border};
    border-radius: 6px;
    color: ${colors.primary};
    font-size: 1.4rem;
    font-weight: 700;
    padding: 8px 24px;
    max-width: max-content;
  }
`;
