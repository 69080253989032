import * as Styled from "./VendorEmptyTab.styles";

import getTabUserProfile, { TabUserProfile } from "@utils/getTabUserProfile";
import { useCallback, useEffect, useState } from "react";

import Button from "@component/Button/Button";
import CheckIcon from "@mui/icons-material/Check";
import TabActionSheet from "@component/TabActionSheet/TabActionSheet";
import UnderageIcon from "@vendor/assets/underage-icon.png";
import VerifiedIcon from "@vendor/assets/verified-icon.png";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

interface EmptyTabProps {
  placeCode?: string;
  tabNumber?: string;
}

function VendorEmptyTab({ tabNumber, placeCode }: EmptyTabProps) {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<TabUserProfile | undefined>();

  const { closeOpenTab } = useTab();

  const navigate = useNavigate();

  const isAgedVerified = userProfile?.state === "VERIFIED";

  const handleUserProfile = useCallback(async () => {
    try {
      const result = await getTabUserProfile();
      setUserProfile(result);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    void handleUserProfile();
  }, [handleUserProfile]);

  const handleActionSheetIsOpen = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  const handleCloseEmptyTab = useCallback(async () => {
    setIsLoading(true);

    const closeAndReturnNextScreen = await closeOpenTab({ tip: "20" });

    handleActionSheetIsOpen();

    if (closeAndReturnNextScreen) {
      navigate(`/venue/${placeCode}/voided`, {
        replace: true,
      });
    }

    setIsLoading(false);
  }, [closeOpenTab, handleActionSheetIsOpen, navigate, placeCode]);

  const handleActivateSelfPour = useCallback(() => {
    console.log("handleActivateSelfPour");
  }, []);

  const handleActivateGame = useCallback(() => {
    console.log("handleActivateGame");
  }, []);

  return (
    <Styled.Wrapper direction="column" className="empty-tab">
      <Styled.Container direction="column">
        {userProfile?.faceImageUrl && (
          <Styled.ProfileImgContainer direction="column">
            <img src={userProfile.faceImageUrl} alt="User Profile" />
            <span className={isAgedVerified ? "verified" : "underage"}>
              {isAgedVerified ? "Age Verified!" : "Underage"}
            </span>
            <img
              className="icon"
              src={isAgedVerified ? VerifiedIcon : UnderageIcon}
              alt="Verification icon"
            />
          </Styled.ProfileImgContainer>
        )}
        {!userProfile?.faceImageUrl && (
          <>
            <Styled.CheckIconContainer size={50}>
              <CheckIcon />
            </Styled.CheckIconContainer>
            <Styled.Title>Your Tab is Open!</Styled.Title>
          </>
        )}
        <Styled.TabNumber>{tabNumber}</Styled.TabNumber>
        <Button text="Close Tab" onClick={handleActionSheetIsOpen} />
        <p>
          Your tab is open! Use your tab number above with any server or serve
          yourself below.
        </p>
      </Styled.Container>
      <Styled.ButtonContainer direction="column">
        <Button
          className="btn_background"
          color="secondary"
          text="Activate Self-Pour"
          onClick={handleActivateSelfPour}
        />
        <Button
          className="btn_background"
          color="secondary"
          text="Activate Game"
          onClick={handleActivateGame}
        />
      </Styled.ButtonContainer>
      <TabActionSheet
        isActionSheetOpen={isActionSheetOpen}
        onActionSheetOpen={handleActionSheetIsOpen}
        onPerformAction={() => {
          void handleCloseEmptyTab();
        }}
        title="Do you really want to close your tab?"
        message="At Bressi you can keep your tab open all night. Leave, come back and your tab will be open. And if you forget, we'll close it out automatically the next day"
        tabNumber={tabNumber}
        actionText="Continue"
        isActionLoading={isLoading}
        customIcon={<></>}
      />
    </Styled.Wrapper>
  );
}

export default VendorEmptyTab;
