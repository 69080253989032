import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  .MuiDivider-root {
    margin: 16px 0 8px;
  }

  .cta-button {
    margin-top: 8px;
  }
`;

export const Title = styled.h2`
  margin: 32px 0 16px;
  font-size: 2.4rem;
  line-height: 2.8rem;
`;

export const ButtonContainer = styled(StyledContainer)`
  gap: 16px;
`;
