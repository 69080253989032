import React, { useEffect, useState } from "react";

import ComponentLoader from "@component/Loading/ComponentLoader";
import CreateWebTabAccount from "@component/CreateWebTabAccount/CreateWebTabAccount";
import FadeIn from "components/Loading/FadeIn";
import { isProd } from "@utils/constants";
import { useLocation } from "react-router-dom";
import usePageTitle from "@hooks/usePageTitle";
import { useTab } from "@context/TabContext";

function Account(): React.ReactElement {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { pathname } = useLocation();
  const location = useLocation();
  const isAddPaymentScreen = location.pathname.includes("addpayment");

  usePageTitle(
    isAddPaymentScreen ? "Add New Payment Method" : "Complete Your Account"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { generateTabInstance, tabInstance } = useTab();

  useEffect(() => {
    void generateTabInstance();
  }, [generateTabInstance]);

  useEffect(() => {
    if (isProd) {
      setIsVisible(!!tabInstance);
    } else {
      setIsVisible(true);
    }
  }, [tabInstance]);

  if (!isVisible) {
    return <ComponentLoader />;
  }

  return (
    <FadeIn isVisible={isVisible}>
      <CreateWebTabAccount clientInstance={tabInstance} />
    </FadeIn>
  );
}

export default Account;
