import { colors, fonts } from "@theme/Themes";

import MaskedInput from "react-text-mask";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Title = styled.h2`
  margin: 32px 0 4px;
  font-size: 2.4rem;
  line-height: 2.8rem;
`;

export const FormattedPhoneNumber = styled.span`
  color: ${colors.textSecondary};
  font-size: 1.8rem;
  line-height: 2.2rem;
`;

export const Container = styled(StyledContainer)`
  margin: 24px 0 32px 0;

  button {
    margin: 16px 0;
  }
`;

export const ResendCode = styled.div`
  align-items: center;
  color: ${colors.textSecondary};
  display: flex;
  //font-size: 1.2rem;
  gap: 6px;
  justify-content: center;

  button {
    color: ${colors.text};
    font-size: 1.8rem;
    padding: 0 !important;
    margin: 0;
    width: auto;

    &:hover,
    &:focus {
      background-color: inherit;
    }

    &.sent {
      background-color: transparent !important;
      color: ${colors.success} !important;
    }
  }
`;

export const InputContainer = styled.div`
  background-color: ${colors.lighterBackground};
  align-items: center;
  display: flex;
  border: 2px solid ${colors.borderLight};
  border-radius: 6px;

  span {
    border-right: 2px solid ${colors.borderLight};
    padding: 12px;
  }
`;

export const Input = styled(MaskedInput)`
  border: none;
  background-color: transparent;
  font-family: ${fonts.textFont};
  font-size: 2rem;
  line-height: 2rem;
  padding: 12px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 1.4rem;
  color: ${colors.error};
  margin-top: 2px;
  padding: 0 8px;
`;
