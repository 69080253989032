import * as Styled from "./TabPaytronixForm.styles";

import { useCallback, useState } from "react";

function TabPaytronixForm() {
  const [placeCode, setPlaceCode] = useState<string | undefined>("37UTRKXHZY");

  const url = window.location.host;

  const handlePlaceCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPlaceCode(event.currentTarget.value);
    },
    []
  );

  return (
    <Styled.Container direction="column">
      <h2>Paytronix - Login Form</h2>

      <Styled.Form
        id="submitForm"
        method="POST"
        action={`https://${url}/venue/${placeCode}`}
      >
        <input
          type="hidden"
          name="token"
          defaultValue={process.env.REACT_APP_PAYTRONIX_API_TOKEN}
        />
        <Styled.FormRow direction="column">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            defaultValue="Rob"
            required
          />
        </Styled.FormRow>
        <Styled.FormRow direction="column">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            defaultValue="Arroyo"
            required
          />
        </Styled.FormRow>
        <Styled.FormRow direction="column">
          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            defaultValue="2221111111"
            required
          />
        </Styled.FormRow>
        <Styled.FormRow direction="column">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            defaultValue="rob.arroyo@rooam.co"
            required
          />
        </Styled.FormRow>
        <Styled.FormRow direction="column">
          <label htmlFor="memberId">Member ID:</label>
          <input
            type="text"
            id="memberId"
            name="memberId"
            defaultValue="1300342431"
            required
          />
        </Styled.FormRow>
        <Styled.FormRow direction="column">
          <label htmlFor="placeCode">Place Code:</label>
          <input
            type="text"
            id="placeCode"
            name="placeCode"
            defaultValue="37UTRKXHZY"
            required
            onChange={handlePlaceCode}
          />
        </Styled.FormRow>
        <Styled.FormRow direction="column">
          <label htmlFor="grantToken">Grant token:</label>
          <input type="text" id="grantToken" name="token" required />
        </Styled.FormRow>
        <input type="submit" value="Submit" id="submitButton"></input>
      </Styled.Form>
    </Styled.Container>
  );
}

export default TabPaytronixForm;
