import {
  Details,
  AccordionContainer as SummaryAccordionContainer,
  RowContainer as SummaryRowContainer,
  Title as SummaryTitle,
} from "@component/Summary/Summary.styles";

import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  gap: 3px;

  .MuiAccordionSummary-content {
    > * {
      color: ${colors.textSecondary} !important;
    }
  }

  .MuiAccordionDetails-root {
    > div > div {
      color: ${colors.textSecondary} !important;
    }
  }
`;

export const RowContainer = styled(SummaryRowContainer)``;

export const Row = styled(StyledContainer)`
  color: ${colors.textSecondary};
  font-weight: 500;
  font-size: 1.7rem;

  &.tab-total {
    background-color: ${colors.paper};
    color: ${colors.text};
    margin: 8px 0 22px;
    padding: 21px 0;
    position: relative;
    z-index: 1;

    span {
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.2rem;
    }

    &:before {
      background-color: ${colors.paper};
      border-bottom: 1px solid ${colors.borderDark};
      border-top: 1px solid ${colors.borderDark};
      content: " ";
      height: 100%;
      left: -50%;
      position: absolute;
      top: 0;
      width: 200%;
      z-index: -1;
    }
  }

  &.discount {
    color: ${colors.success};
  }

  span,
  div {
    font-size: 1.7rem;
    font-weight: 500;

    &:last-child {
      margin-left: auto;
    }
  }
`;

export const AccordionContainer = styled(SummaryAccordionContainer)`
  .view-items-btn {
    margin: 16px auto 0;
  }

  &#summary-accordion {
    > .MuiButtonBase-root,
    .MuiAccordionSummary-root,
    .MuiAccordionDetails-root {
      background-color: ${colors.lighterBackground} !important;

      &:before,
      &:after {
        background-color: ${colors.borderDark} !important;
      }
    }

    > .MuiAccordionSummary-root {
      border-top: 1px solid ${colors.borderDark} !important;
    }

    .MuiAccordionDetails-root {
      &.overwrite {
        border-bottom: 1px solid ${colors.borderDark};
      }
    }

    > * {
      position: relative;
      z-index: 1;
    }

    &:after {
      background: ${colors.lighterBackground};
      border-bottom: 1px solid ${colors.borderDark};
      border-top: 1px solid ${colors.borderDark};
      content: " " !important;
      height: 100%;
      left: -50%;
      position: absolute;
      top: 0;
      width: calc(200%);
      z-index: 0;
    }
  }

  &.inner-accordion {
    > * {
      font-size: 1.7rem;
      font-weight: 500;
    }

    .MuiAccordionSummary-root,
    .MuiAccordionDetails-root {
      background-color: ${colors.background};
    }
  }
`;

export const AccordionDetails = styled(Details)`
  &.MuiAccordionDetails-root {
    &.overwrite {
      &:after {
        content: none;
      }
    }

    &:not(.overwrite) {
      > div {
        &:first-child {
          border-top: ${colors.borderDark} solid 1px !important;
        }
        &:last-child {
          border-bottom: ${colors.borderDark} solid 1px !important;
        }

        p {
          margin-top: 0;
          font-size: 1.4rem;
        }
      }
    }
  }

  &.collapsible-details {
    > div {
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const Title = styled(SummaryTitle)`
  span {
    font-size: 18px;
  }
`;

export const ItemListContainer = styled(StyledContainer)`
  border-bottom: 1px solid ${colors.borderDark};
  margin-top: 16px;
  padding: 0 0 16px;
  width: 100%;

  .MuiAvatar-circular {
    height: 34px !important;
    width: 34px !important;

    .MuiAvatar-root {
      font-size: 1.4rem;
      height: 24px !important;
      width: 24px !important;
    }
  }

  .MuiPaper-root {
    background-color: ${colors.borderDark};
    border: 1px solid ${colors.borderDark};
    padding: 6px 8px;

    > div {
      span {
        &:last-child {
          display: none;
        }
      }
    }
  }
`;
