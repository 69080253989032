import { colors, fonts } from "@theme/Themes";

import braintree from "braintree-web";
import { isDev } from "utils/constants";

interface createHostedFieldsFormProps {
  client: any;
  setHostedFieldsInstance: (
    value: React.SetStateAction<braintree.HostedFields | undefined>
  ) => void;
}

function createHostedFieldsForm({
  client,
  setHostedFieldsInstance,
}: createHostedFieldsFormProps) {
  braintree.hostedFields.create(
    {
      client,
      styles: {
        input: {
          "font-family": fonts.textFont,
          "font-size": "18px",
          "font-weight": "400",
          "font-style": "normal",
          color: colors.text,
        },
        ".valid": {
          color: colors.text,
        },
        ".invalid": {
          color: colors.error,
        },
      },
      fields: {
        number: {
          selector: "#cc-number",
          placeholder: "Card Number",
          prefill: isDev ? "4111 1111 1111 1111" : undefined,
        },
        cvv: {
          selector: "#cc-cvv",
          placeholder: "CVV",
          prefill: isDev ? "123" : undefined,
        },
        expirationDate: {
          selector: "#cc-expiration",
          placeholder: "MM / YY",
          prefill: isDev ? "12/26" : undefined,
        },
        postalCode: {
          selector: "#cc-zip",
          placeholder: "Zip Code",
          prefill: isDev ? "90210" : undefined,
        },
      },
    },
    function (err, hfInstance) {
      // We don't need FE error thrown when hosted fields are already there
      if (err && err.code !== "HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME") {
        console.error(
          "Add Payment Method > Braintree Hosted Fields failure",
          err
        );
        return null;
      }

      setHostedFieldsInstance(hfInstance);
    }
  );
}

export default createHostedFieldsForm;
