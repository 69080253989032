import {
  QueryKey,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import getTabFeed, { TabFeedData } from "@utils/getTabFeed/getTabFeed";
import getTabReceipt, { TabReceiptResponse } from "@utils/getTabReceipt";
import getTabTips, { TipProps, TipsData } from "@utils/getTabTips";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Client } from "braintree-web";
import { PaymentMethodProps } from "@component/TabPaymentMethodList/TabPaymentMethodList";
import closeTab from "@utils/closeTab";
import getCurrentTip from "@utils/getCurrentTip";
import getTabBraintreeInstance from "@utils/getTabBraintreeInstance";
import getTabPaymentMethods from "@utils/getTabPaymentMethods";
import openTab from "@utils/openTab";
import { queryKeys } from "@utils/constants";
import { setItem } from "@hooks/useCookies";
import { setTag } from "@sentry/react";
import { useAuth } from "./AuthContext";

interface CustomError extends Error {
  response: {
    data: {
      message: string;
    };
  };
}

interface GetTabFeedRequest {
  placeCode: string;
  tabNumber: string;
}

export const feedQueryKeyBuilder = ({
  placeCode,
  tabNumber,
}: GetTabFeedRequest) =>
  [queryKeys.tabFeed, { placeCode, tabNumber }] as QueryKey;

interface TabContextProps {
  closeOpenTab: ({ tip }: { tip: string }) => Promise<any>;
  error: unknown;
  feed: TabFeedData | undefined;
  generateTabInstance: () => Promise<void>;
  getTabNumber: () => Promise<{ tabNumber: string }>;
  isFeedLoading: boolean;
  isOverdue: boolean | undefined;
  isReceiptLoading: boolean;
  isRooamCredit: boolean | undefined;
  feedQueryKey: QueryKey | undefined;
  placeCode: string | undefined;
  paymentMethods: PaymentMethodProps[] | undefined;
  receipt: TabReceiptResponse | undefined;
  refetchFeed: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<TabFeedData, unknown>>;
  refreshPaymentMethods: () => void;
  selectedPaymentMethod: PaymentMethodProps | undefined;
  selectedTip: TipProps | undefined;
  setPaymentMethods: React.Dispatch<
    React.SetStateAction<PaymentMethodProps[] | undefined>
  >;
  setSelectedPaymentMethod: React.Dispatch<
    React.SetStateAction<PaymentMethodProps | undefined>
  >;
  tabInstance: Client | undefined;
  setIsOverdue: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  tabNumber: string | undefined;
  tabToken: string | undefined;
  tabTotal: string | undefined;
  tips: TipsData | undefined;
  updateSelectedTip: (tip: TipProps | undefined) => void;
}

export const TabContext = createContext<TabContextProps | null>(null);

TabContext.displayName = "TabContext";

function TabProvider(props: any): React.ReactElement {
  const [tabInstance, setTabInstance] = useState<Client | undefined>();
  const [tabToken, setTabToken] = useState<string | undefined>();
  const [tabNumber, setTabNumber] = useState<string | undefined>();
  const [tips, setTips] = useState<TipsData>();
  const [selectedTip, setSelectedTip] = useState<TipProps | undefined>();
  const [tabTotal, setTabTotal] = useState<string | undefined>();
  const [enableReceiptQuery, setEnableReceiptQuery] = useState<boolean>(false);
  const [isOverdue, setIsOverdue] = useState<boolean>();
  const [receiptRetries, setReceiptRetries] = useState<number>(0);
  const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(true);
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentMethodProps[] | undefined
  >();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethodProps | undefined
  >();
  const [isRooamCredit, setIsRooamCredit] = useState<boolean | undefined>(
    false
  );

  const { webTabSession, setWebTabSession, isAuthenticated } = useAuth();
  const { placeCode } = useParams();

  const location = useLocation();
  const path = location.pathname;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  // validate path to refetch feed
  const validFeedPaths = ["/tab", "/overdue"];
  const isValidFeedPath = validFeedPaths.some((subPath) =>
    path.includes(subPath)
  );

  // validate path to refetch receipt
  const validReceiptPaths = ["/complete"];
  const isValidReceiptPath = validReceiptPaths.some((subPath) =>
    path.includes(subPath)
  );

  const generateTabInstance = useCallback(async () => {
    try {
      const { authorization, instance } = await getTabBraintreeInstance();

      if (authorization && instance) {
        setTabInstance(instance);
        setTabToken(authorization);
      } else {
        console.error("Authorization or instance is missing.");
      }
    } catch (error) {
      console.error("Error while fetching Tab Braintree instance:", error);
    }
  }, []);

  // Get Payment Methods
  const handleGetTabPaymentMethods = useCallback(() => {
    getTabPaymentMethods()
      .then((response) => {
        setPaymentMethods(response);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (webTabSession?.xRooamSession) {
      handleGetTabPaymentMethods();
    }
  }, [handleGetTabPaymentMethods, webTabSession?.xRooamSession]);

  // set selected payment method
  useEffect(() => {
    if (paymentMethods) {
      const defaultPaymentMethod = paymentMethods.find(
        (method) => method.default && method
      );

      setSelectedPaymentMethod(defaultPaymentMethod);
    }
  }, [paymentMethods]);

  // Get Check Data
  const feedQueryKey = useMemo(
    () =>
      feedQueryKeyBuilder({
        placeCode: placeCode ?? "",
        tabNumber: tabNumber ?? "",
      }),
    [placeCode, tabNumber]
  );

  const {
    data: feed,
    error,
    isLoading: isFeedLoading,
    refetch: refetchFeed,
  } = useQuery(feedQueryKey, getTabFeed, {
    enabled: isValidFeedPath,
    refetchInterval: 10_000,
    staleTime: 10_000,
    onError(err) {
      const feedError = err as CustomError;

      if (
        feedError?.message.includes(
          "getTabFeed request failed with status 401 (Unauthorized)"
        )
      ) {
        if (!isAuthenticated || !webTabSession?.isSessionOpen) {
          navigate(`/venue/${placeCode}/phone`, {
            replace: true,
          });
        }
      }
    },
  });

  const {
    data: receipt,
    refetch: receiptRefetch,
    isFetching: isReceiptFetching,
    isRefetching: isReceiptRefetching,
  } = useQuery(["receiptData", `${placeCode}/complete`], getTabReceipt, {
    enabled: enableReceiptQuery ?? isValidReceiptPath,
    staleTime: Infinity,
    refetchInterval: 5_000,
    onSettled(response) {
      setReceiptRetries(receiptRetries + 1);

      if (response?.overdue?.hasOverduePayment) {
        setIsOverdue(response?.overdue?.hasOverduePayment);
        setEnableReceiptQuery(false);
        setIsReceiptLoading(false);
        setReceiptRetries(0);
      }

      if (response?.data?.receipt) {
        setIsOverdue(false);
        setEnableReceiptQuery(false);
        setIsReceiptLoading(false);
        setReceiptRetries(0);
        void refetchFeed();
      }

      if (receiptRetries >= 6) {
        setIsOverdue(false);
        setEnableReceiptQuery(false);
        setIsReceiptLoading(false);
        setReceiptRetries(0);
      }
    },
    onError(err) {
      const feedError = err as CustomError;

      if (
        feedError?.message.includes(
          "getTabReceipt > request failed with status 401 (Unauthorized)"
        )
      ) {
        if (!isAuthenticated || !webTabSession?.isSessionOpen) {
          setIsOverdue(false);
          setEnableReceiptQuery(false);
          setIsReceiptLoading(false);

          navigate(`/venue/${placeCode}/phone`, {
            replace: true,
          });
        }
      }
    },
  });

  const getTabNumber = useCallback(async () => {
    if (placeCode) {
      setTabNumber(undefined);

      const data = await openTab(placeCode);
      const _tabNumber = data?.tabNumber;

      // to reset feed & receipt data if necessary
      await receiptRefetch();
      await refetchFeed();

      if (_tabNumber) {
        setTabNumber(_tabNumber);

        setTag("ticketNumber", _tabNumber);

        if (webTabSession && setWebTabSession) {
          setWebTabSession({
            ...webTabSession,
            tabData: {
              ...webTabSession.tabData,
              tabNumber: _tabNumber,
            },
            selectedPaymentMethod: feed?.paymentMethod,
          });
        }
      } else {
        feed?.tab.tabNumber && feed?.open && setTabNumber(feed?.tab.tabNumber);
      }

      return data;
    }
  }, [
    feed?.open,
    feed?.paymentMethod,
    feed?.tab.tabNumber,
    placeCode,
    receiptRefetch,
    refetchFeed,
    setWebTabSession,
    webTabSession,
  ]);

  // Refetch the data when the URL changes to include "/complete"
  useEffect(() => {
    if (path.includes("/complete")) {
      setEnableReceiptQuery(true);
      void receiptRefetch();
    }
  }, [path, receiptRefetch, refetchFeed]);

  useEffect(() => {
    if (isReceiptFetching ?? isReceiptRefetching) {
      setIsReceiptLoading(true);
    }
  }, [isReceiptFetching, isReceiptRefetching]);

  const closeOpenTab = useCallback(
    async ({ tip }: { tip: string }) => {
      if (placeCode) {
        const closeAction = await closeTab({ placeCode, tip });

        return closeAction;
      }
    },
    [placeCode]
  );

  const getTips = useCallback(async (subtotal: string) => {
    const data = await getTabTips(subtotal);

    return setTips(data);
  }, []);

  useEffect(() => {
    if (placeCode && tabNumber && feed?.totals?.subTotal) {
      const subtotalBeforeDiscount = (
        parseFloat(feed.totals.subTotal) + parseFloat(feed.totals.discount)
      ).toFixed(2);

      void getTips(subtotalBeforeDiscount);
    }
  }, [feed?.totals, getTips, placeCode, tabNumber]);

  useEffect(() => {
    const credit = parseInt(
      feed?.totals.credit ?? receipt?.data?.receipt.creditApplied ?? "0.00"
    );

    if (credit > 0) {
      setIsRooamCredit(true);
    } else if (!isRooamCredit) {
      const isRooamCreditFlow = path.includes("rooamcredit");

      setIsRooamCredit(!!isRooamCreditFlow);
    }
  }, [
    feed?.totals.credit,
    isRooamCredit,
    path,
    receipt?.data?.receipt.creditApplied,
  ]);

  // set saved tip or default tip
  useEffect(() => {
    if (
      !selectedTip &&
      tips &&
      tabNumber &&
      feed &&
      feed?.tab?.items?.length > 0
    ) {
      const currentTip = getCurrentTip(tabNumber);
      const defaultTipPercentage = tips.defaultTip?.percentage ?? "0";
      const subtotal = feed.totals.subTotal ?? "0";

      if (!currentTip || (!selectedTip && currentTip.amount === 0)) {
        const tipToSet = {
          percentage: defaultTipPercentage,
          amount: parseFloat(subtotal) * (defaultTipPercentage / 100),
        };

        setSelectedTip(tipToSet);

        if (webTabSession && setWebTabSession) {
          setWebTabSession({
            ...webTabSession,
            tabData: {
              ...webTabSession?.tabData,
              tip: tipToSet,
            },
            selectedPaymentMethod: feed?.paymentMethod,
          });
        }

        return setItem(`userTip_${tabNumber}`, JSON.stringify(tipToSet), 1);
      }

      if (webTabSession && setWebTabSession) {
        setWebTabSession({
          ...webTabSession,
          tabData: {
            ...webTabSession?.tabData,
            tip: currentTip,
          },
        });
      }

      setSelectedTip(currentTip);
    }
  }, [feed, selectedTip, setWebTabSession, tabNumber, tips, webTabSession]);

  const updateSelectedTip = useCallback(
    (tip: TipProps | undefined) => {
      setSelectedTip(tip);
    },
    [setSelectedTip]
  );

  // Update Selected Tip amount when new items get added.
  useEffect(() => {
    const defaultTipPercentage = tips?.defaultTip?.percentage ?? 0;
    const subtotal = feed?.totals?.subTotal ?? "0";
    const tipToSet = {
      percentage: defaultTipPercentage,
      amount: parseFloat(subtotal) * (defaultTipPercentage / 100),
    };

    setSelectedTip(tipToSet);
  }, [feed?.tab?.items, feed?.totals?.subTotal, tips?.defaultTip?.percentage]);

  useEffect(() => {
    if (feed?.tab.items) {
      const taxesFees =
        parseFloat(feed?.totals.tax ?? "0") +
        parseFloat(feed?.totals.fee ?? "0");
      const userTip = selectedTip?.amount ?? 0;
      const serviceCharges = parseFloat(feed?.totals?.serviceCharges ?? "0");
      let total = taxesFees + serviceCharges + userTip;
      const discount = parseFloat(feed?.totals?.discount ?? "0");
      const partialPayment = parseFloat(feed?.totals?.partialPayment ?? "0");

      feed?.tab.items.map((item) => {
        const itemPrice = parseFloat(item.pricePerUnit ?? "0");
        total = total + item.quantity * itemPrice - discount - partialPayment;

        setTabTotal(total.toFixed(2).toString());

        return total;
      });

      if (webTabSession && setWebTabSession) {
        setWebTabSession({
          ...webTabSession,
          tabData: {
            ...webTabSession?.tabData,
            tabNumber: feed.tab.tabNumber,
          },
          selectedPaymentMethod: feed?.paymentMethod,
        });
      }
    }
    // 🚨 Don't add webTabSession to the dependency array to avoid multiple re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feed?.tab.items,
    feed?.tab.tabNumber,
    feed?.totals,
    feed?.paymentMethod,
    selectedTip?.amount,
    setWebTabSession,
  ]);

  // reset tabNumber if session is invalid or tab is closed
  useEffect(() => {
    if (receipt && receipt?.data?.receipt.ticketNumber === tabNumber) {
      void queryClient.invalidateQueries();
    }
  }, [queryClient, receipt, tabNumber]);

  useEffect(() => {
    if (feed && tabNumber !== feed?.tab?.tabNumber) {
      setTabNumber(feed.tab.tabNumber);
    }
  }, [feed, tabNumber]);

  const value: TabContextProps = useMemo(
    () => ({
      closeOpenTab,
      error,
      feed,
      feedQueryKey,
      generateTabInstance,
      getTabNumber,
      isFeedLoading,
      isOverdue,
      isReceiptLoading,
      isRooamCredit,
      placeCode,
      paymentMethods,
      receipt,
      refetchFeed,
      refreshPaymentMethods: handleGetTabPaymentMethods,
      selectedPaymentMethod,
      selectedTip,
      setIsOverdue,
      setPaymentMethods,
      setSelectedPaymentMethod,
      tabInstance,
      tabNumber,
      tabToken,
      tabTotal,
      tips,
      updateSelectedTip,
    }),
    [
      closeOpenTab,
      error,
      feed,
      feedQueryKey,
      generateTabInstance,
      getTabNumber,
      isFeedLoading,
      isOverdue,
      isReceiptLoading,
      isRooamCredit,
      placeCode,
      paymentMethods,
      receipt,
      refetchFeed,
      handleGetTabPaymentMethods,
      selectedPaymentMethod,
      selectedTip,
      tabInstance,
      tabNumber,
      tabToken,
      tabTotal,
      tips,
      updateSelectedTip,
    ]
  );

  return (
    <TabContext.Provider value={value} {...props} />
  ) as React.ReactElement;
}

export default TabProvider;

// Hook to consume TabProvider
export function useTab(): TabContextProps {
  const context = useContext(TabContext);

  if (context === undefined) {
    throw new Error("useTab must be used within a TabProvider");
  }

  if (context === null) {
    throw new Error("TabProvider supplied null context");
  }

  return context;
}
