import styled, { keyframes } from "styled-components";

import ChevronPopupIcon from "@vendor/assets/images/chevron-icon-popup.png";

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-16px);
  }
`;

export const BouncingWrapper = styled.div`
  animation: ${bounce} 1.3s ease-in-out infinite;

  img {
    height: 68px;
    margin: 8px 0;
  }
`;

function BouncingChevronDown() {
  return (
    <BouncingWrapper>
      <img src={ChevronPopupIcon} alt="Chevron Icon Popup" />
    </BouncingWrapper>
  );
}

export default BouncingChevronDown;
