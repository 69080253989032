import { environment } from "./constants";
import getNextScreen from "./getNextScreen";

interface AddPaymentMethodRequest {
  nonce: string;
  paymentType?: string;
  deviceData?: string;
}

async function addTabPaymentMethod({
  nonce,
  paymentType,
  deviceData,
}: AddPaymentMethodRequest): Promise<any> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  xRooamSession && headers.append("x-rooam-session", xRooamSession);
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);

  const body = JSON.stringify({
    nonce,
    deviceData,
    paymentType,
  });

  try {
    const response = await fetch(`${environment.baseUrl}/payments`, {
      method: "POST",
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error(
        `addTabPaymentMethod > Request failed with status ${response.status}`
      );
    }

    return await getNextScreen();
  } catch (error) {
    console.error(
      "addTabPaymentMethod > Error adding Payment Method to Braintree",
      error
    );
    throw error;
  }
}

export default addTabPaymentMethod;
