import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  text-align: center;
`;

export const VerificationImage = styled.img`
  border-radius: 6px;
`;

export const Paragraph = styled.p`
  margin: 32px auto;
  max-width: 410px;
  text-align: center;
`;

export const SmallText = styled.small`
  color: ${colors.textSecondary};
  margin-bottom: 32px;
`;

export const ButtonContainer = styled(StyledContainer)`
  gap: 12px;
`;

export const StepContainer = styled(StyledContainer)`
  > div {
    color: #000000;
  }
`;

export const LoaderContainer = styled(StyledContainer)`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  span {
    &:before {
      border-color: ${colors.background};
    }
    &:after {
      border-color: ${colors.lighterBackground};
    }
  }
`;

export const ResetPermissionContainer = styled(StyledContainer)`
  align-items: center;
  justify-content: center;
  text-align: center;

  .MuiSvgIcon-root {
    font-size: 9rem;
  }
`;
