import * as Styled from "./TabVoided.styles";

import VoidedIcon from "@component/Icons/VoidedIcon";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import usePageTitle from "@hooks/usePageTitle";
import { useTab } from "@context/TabContext";

function VoidedTab() {
  usePageTitle("Tab Voided");

  const navigate = useNavigate();
  const { getTabNumber, placeCode } = useTab();

  const handleOpenTab = useCallback(() => {
    void getTabNumber();

    navigate(`/venue/${placeCode}/tab`, {
      replace: true,
    });
  }, [getTabNumber, navigate, placeCode]);

  return (
    <Styled.Container direction="column" className="tab-voided">
      <Styled.ContentContainer direction="column">
        <VoidedIcon />
        <Styled.Title>Tab Voided</Styled.Title>
        <span>No payment was made.</span>
      </Styled.ContentContainer>
      <Styled.OpenTabButton
        color="secondary"
        className="overwrite"
        text="Open Another Tab"
        onClick={handleOpenTab}
      />
    </Styled.Container>
  );
}

export default VoidedTab;
