import * as Sentry from "@sentry/browser";

import getTabOverdue, { TabOverdueData } from "./getTabOverdue";

import { TabItemData } from "./getTabFeed/getTabFeed";
import { environment } from "./constants";

export interface ReceiptData {
  receipt: {
    total: string;
    subTotal: string;
    fee: string;
    tip: string;
    tax: string;
    ticketNumber: string;
    serviceCharges: string;
    partialPayment: string;
    discounts: string[];
    totalWithCredit: string;
    creditApplied: string;
  };
  place: {
    name: string;
  };
  items: TabItemData[];
}

export interface TabReceiptResponse {
  data: ReceiptData | undefined;
  overdue: TabOverdueData | undefined;
}

async function getTabReceipt(): Promise<TabReceiptResponse | undefined> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);

  try {
    const xRooamSession =
      localStorage.getItem("x-rooam-session") ??
      localStorage.getItem("X-ROOAM-Session");
    xRooamSession && headers.append("X-Rooam-Session-Static", xRooamSession);

    if (!xRooamSession) {
      throw new Error("getTabReceipt > Session token not found");
    }

    const response = await fetch(`${environment.baseUrl}/receipt`, {
      method: "GET",
      headers,
    });

    if (response.status === 401) {
      throw new Error(
        "getTabReceipt > request failed with status 401 (Unauthorized)"
      );
    }

    if (response.status === 409) {
      throw new Error(
        "getTabReceipt > request failed with status 409. It'll retry again."
      );
    }

    if (!response.ok) {
      const overdue = await getTabOverdue();

      if (overdue?.hasOverduePayment) {
        return {
          data: undefined,
          overdue,
        };
      }

      console.warn(
        `getTabReceipt > Request failed with status ${response.status}`
      );
    }

    const data = await response.json();

    return {
      data,
      overdue: undefined,
    };
  } catch (error) {
    console.error("An unexpected error occurred:", error);
    Sentry.captureException(error);
  }
}

export default getTabReceipt;
