import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Container = styled(StyledContainer)`
  margin: 32px 0;
`;

function TabQa() {
  const navigate = useNavigate();

  const handlePaytronixTab = useCallback(() => {
    navigate("/qa/tab-paytronix-form");
  }, [navigate]);

  return (
    <Container direction="column">
      <Button
        text="Open Paytronix Tab"
        onClick={() => {
          void handlePaytronixTab();
        }}
      />
    </Container>
  );
}

export default TabQa;
