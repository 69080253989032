import { useEffect, useState } from "react";

export function usePermissions() {
  const [state, setState] = useState("unknown");

  useEffect(() => {
    try {
      navigator.permissions
        .query({ name: "camera" as PermissionName })
        .then(function (result) {
          setState(result.state);
        })
        .catch(() => {
          setState("unknown");
        });
    } catch (e) {
      setState("unknown");
    }
  }, []);

  return state;
}
