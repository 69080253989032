import { environment } from "./constants";

async function getTabPaymentMethods(): Promise<any> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);

  try {
    const xRooamSession = localStorage.getItem("x-rooam-session");

    if (!xRooamSession) {
      throw new Error("getTabPaymentMethods > Session token not found");
    }

    xRooamSession && headers.append("x-rooam-session", xRooamSession);

    const response = await fetch(`${environment.baseUrl}/payments`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(
        `getTabPaymentMethods > Request failed with status ${response.status}`
      );
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(
      "getTabPaymentMethods > Error getting user's payment methods",
      error
    );
  }
}

export default getTabPaymentMethods;
