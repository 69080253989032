import * as Styled from "./VendorFooter.styles";

import LineZeroImg from "@assets/vendor/bressitab/logos/linezero-logo.png";
import { useLocation } from "react-router-dom";
import { useTab } from "@context/TabContext";

export default function VendorFooter(): React.ReactElement {
  const { feed } = useTab();

  const items = feed?.tab?.items && feed?.tab?.items.length > 0;

  const year = new Date().getFullYear();

  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const currentPage = pathSegments.pop();

  return (
    <>
      <Styled.Container
        direction="column"
        className={`${currentPage}-footer ${items ? "has-items" : ""}`}
      >
        <span>© Copyright Bressi Garage {year}</span>
        <Styled.LineZeroLogo src={LineZeroImg} alt="Line Zero Logo" />
      </Styled.Container>

      <Styled.PageBackground
        id={`${currentPage}-bg`}
        className={items ? "has-items" : ""}
      />
    </>
  );
}
