import apiClient from "./api/apiClient";
import { environment } from "utils/constants";

interface Result {
  braintreeToken: string;
}

export async function getTabToken(): Promise<Result> {
  try {
    const token = await apiClient.get({ path: clientTokenEndpoint });

    return token;
  } catch (error) {
    throw new Error("getTabToken > Error getting Braintree Web Tab Token");
  }
}

export const clientTokenEndpoint = `${environment.baseUrl}/payments/token`;
