export const vendorColor = "#dd342e";
export const vendorDefaultTitle = "Rooam Tab: Pay from your phone";

export const rateExperienceTitle = "How was your Rooam Pay experience?";
export const rateExperienceBadHref = "https://forms.gle/n2DDL5nuL3vutkzD8";
export const rateExperienceGreatHref = "https://forms.gle/f3jXNZD1qEhi5nzi9";

export const defaultRedirectionUrl = "/phone";

export const applePayLabels = {
  paymentSheet: {
    totalLabel: "Rooam",
  },
  validation: {
    displayName: "Rooam Pay",
  },
};
