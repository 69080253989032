import PlaceCode, { placeLoader } from "@routes/PlaceCode/PlaceCode";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import Account from "@routes/Account/Account";
import Layout from "@component/Layout/Layout";
import Open from "@routes/Open/Open";
import Overdue from "@routes/Overdue/Overdue";
import Phone from "@routes/Phone/Phone";
import RootErrorBoundary from "@component/RouterErrorBoundary/RootErrorBoundary";
import Tab from "@routes/Tab/Tab";
import TabComplete from "@routes/TabComplete/TabComplete";
import TabInitialRouteWrapper from "@component/TabInitialRouteWrapper/TabInitialRouteWrapper";
import TabPaytronixForm from "@component/TabPaytronixForm/TabPaytronixForm";
import TabQa from "@component/TabQa/TabQa";
import Verify from "@routes/Verify/Verify";
import VerifyIdentity from "@routes/VerifyIdentity/VerifyIdentity";
import Voided from "@routes/Voided/Voided";
import { isDev } from "@utils/constants";
import { useQueryClient } from "react-query";
import { wrapCreateBrowserRouter } from "@sentry/react";

const AppRoutes = () => {
  const queryClient = useQueryClient();

  // When a route does not have an errorElement,
  // errors will bubble up through parent routes.
  // This lets you get as granular or general as you like.

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={<Layout />}
          errorElement={<RootErrorBoundary />}
        >
          <Route path="/qa" element={isDev ? <TabQa /> : <></>} />
          <Route
            path="qa/tab-paytronix-form"
            element={isDev ? <TabPaytronixForm /> : <></>}
          />
          <Route path=":placeCode" element={<TabInitialRouteWrapper />} />
          <Route
            path={"venue/:placeCode"}
            loader={placeLoader(queryClient)}
            element={<PlaceCode />}
          >
            <Route path={"phone"} element={<Phone />} />
            <Route path={"rooamcredit"} element={<Phone />} />
            <Route path={"verify"} element={<Verify />} />
            <Route path={"verifyidentity"} element={<VerifyIdentity />} />
            <Route path={"account"} element={<Account />} />
            <Route path={"open"} element={<Open />} />
            <Route path={"addpayment"} element={<Account />} />
            <Route path={"select"} element={<Open />} />
            <Route path={"tab"} element={<Tab />} />
            <Route
              path={"voided"}
              element={<Voided queryClient={queryClient} />}
            />
            <Route path={"complete"} element={<TabComplete />} />
            <Route path={"overdue"} element={<Overdue />} />
          </Route>
        </Route>
        <Route
          path="/venue/:placeCode/onboarding"
          element={<Layout />}
          errorElement={<RootErrorBoundary />}
        />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppRoutes;

export const isCheckPath = (): boolean => {
  const currentPath = window.location.pathname;
  if (currentPath.length === 1) return false;
  return (
    ["find", "checkout", "complete", "qr/"]
      .map((item) => currentPath.includes(item))
      .filter((value) => value).length === 0
  );
};

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
