import { useCallback, useState } from "react";

import logOutTab from "@utils/logOutTab";
import { useAuth } from "@context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTab } from "@context/TabContext";

export interface UseLogOutReturn {
  handleLogOut: () => Promise<void>;
  isLoginOut: boolean;
}

const useLogOut = (): UseLogOutReturn => {
  const [isLoginOut, setIsLoginOut] = useState(false);

  const { setWebTabSession, didSignOut } = useAuth();

  const navigate = useNavigate();
  const { placeCode, setPaymentMethods } = useTab();

  const queryClient = useQueryClient();

  const handleLogOut = useCallback(async () => {
    setIsLoginOut(true);

    localStorage.removeItem("x-rooam-session");

    setWebTabSession?.({
      phoneNumber: undefined,
      xRooamSession: null,
      isSessionOpen: false,
      tabData: {
        tabNumber: undefined,
        tip: undefined,
      },
      selectedPaymentMethod: undefined,
    });

    setPaymentMethods(undefined);

    await queryClient.invalidateQueries();

    didSignOut();

    const response = await logOutTab();

    if (response.ok) {
      return navigate(`/venue/${placeCode}/phone`, { replace: true });
    }

    setIsLoginOut(false);
    // onCancel();
  }, [
    didSignOut,
    navigate,
    placeCode,
    queryClient,
    setPaymentMethods,
    setWebTabSession,
  ]);

  return {
    handleLogOut,
    isLoginOut,
  };
};

export default useLogOut;
