import { environment } from "@utils/constants";

export interface TabItemData {
  name: string;
  price: string;
  pricePerUnit: string;
  quantity: number;
  // these are Pay Web props
  discount: undefined;
  pricePerUnitInCents: undefined;
  priceInCents: undefined;
}

export interface TabData {
  tabNumber: string;
  items: TabItemData[];
}

export interface TabFeedData {
  open: boolean;
  voided: boolean;
  tab: TabData;
  totals: {
    total: string;
    tax: string;
    subTotal: string;
    serviceCharges: string;
    credit: string;
    fee: string;
    partialPayment: string;
    discount: string;
  };
  paymentMethod: {
    type: string;
    label: string;
    default: boolean;
    id: string;
    image: string;
  };
}

async function getTabFeed(): Promise<TabFeedData> {
  const url = `${environment.baseUrl}/feed`;

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.refererUrl && headers.append("referer", environment.refererUrl);

  try {
    const xRooamSession = localStorage.getItem("x-rooam-session");
    xRooamSession && headers.append("X-ROOAM-Session-Static", xRooamSession);

    if (!xRooamSession) {
      throw new Error("getTabFeed > Session token not found");
    }
    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (response.ok) {
      const data: TabFeedData = await response.json();
      return data;
    } else {
      if (response.status === 401) {
        throw new Error(
          "getTabFeed request failed with status 401 (Unauthorized)"
        );
      }

      throw new Error(
        `getTabFeed > Error fetching data with status: ${response.status}`
      );
    }
  } catch (error) {
    throw new Error((error as Error).message);
  }
}

export default getTabFeed;
