import * as Styled from "./TabPaytronixRewards.styles";

import { useCallback, useEffect, useState } from "react";

import AmountInput from "@component/Inputs/AmountInput/AmountInput";
import Button from "@component/Button/Button";
import StyledSwitch from "@GlobalComponents/StyledSwitch";
import VendorRewardsIcon from "@vendor/components/VendorLogo/VendorRewardsIcon";
import applyTabPaytronixRewardPoints from "@utils/applyTabPaytronixRewardPoints";
import getTabPaytronixRewardPoints from "@utils/getTabPaytronixRewardPoints";
import { isTaoEnabled } from "@utils/constants";
import { useTab } from "@context/TabContext";

interface TabPaytronixRewardsProps {
  onDiscountApplied: (discountToApply: string) => void;
}

function TabPaytronixRewards({ onDiscountApplied }: TabPaytronixRewardsProps) {
  const [selected, setSelected] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [availablePoints, setAvailablePoints] = useState<string | null>(null);
  const [usedPoints, setUsedPoints] = useState<string | null>(null);
  const [pointsToApply, setPointsToApply] = useState<number | undefined>(0);
  const [isExceeded, setIsExceeded] = useState<boolean>(false);
  const [isZeroValue, setIsZeroValue] = useState<boolean>(true);

  const [isRedeemingRewardDollars, setIsRedeemingRewardDollars] =
    useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { feed } = useTab();
  const subtotal = (
    parseFloat(feed?.totals?.subTotal ?? "0") +
    parseFloat(feed?.totals?.discount ?? "0")
  ).toFixed(2);

  const handleSwitch = useCallback(() => {
    setSelected(!selected);
  }, [selected]);

  const handleRewardPoints = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let amount = parseFloat(event.currentTarget.value);
      amount = isNaN(amount) ? 0 : amount;

      const currentPoints = parseFloat(availablePoints ?? "0");
      const total = parseFloat(subtotal);

      const exceeded = amount > currentPoints || amount > total;
      setIsExceeded(exceeded);
      setPointsToApply(exceeded ? 0 : amount);

      setIsZeroValue((exceeded ? 0 : amount) <= 0);
    },
    [availablePoints, subtotal]
  );

  const applyPoints = useCallback(async () => {
    const rewardPoints = await applyTabPaytronixRewardPoints(
      pointsToApply?.toFixed(2) ?? "0"
    );

    if (rewardPoints) {
      const amount = parseFloat(rewardPoints.amount ?? "0");
      const used = parseFloat(rewardPoints.used ?? "0");
      const available = amount - used;

      setAvailablePoints(available.toFixed(2));
      setUsedPoints(rewardPoints.used);
    }
  }, [pointsToApply]);

  const handleApplyRewardPoints = useCallback(() => {
    if (!pointsToApply) {
      setErrorMessage(
        "Can not apply selected amount. Please choose a higher value."
      );
    }

    const points = pointsToApply?.toFixed(2);

    setIsRedeemingRewardDollars(true);
    setErrorMessage(undefined);

    // apply reward points here
    applyPoints()
      .then(() => {
        if (points) {
          setIsRedeemingRewardDollars(false);
          setUsedPoints(points);
          onDiscountApplied(points);
        }
      })
      .catch((error) => console.error(error));
  }, [applyPoints, onDiscountApplied, pointsToApply]);

  const getPoints = useCallback(async () => {
    const rewardPoints = await getTabPaytronixRewardPoints();

    if (rewardPoints) {
      const amount = parseFloat(rewardPoints.amount ?? "0");
      const used = parseFloat(rewardPoints.used ?? "0");
      const available = amount - used;

      setAvailablePoints(available.toFixed(2));
      setUsedPoints(rewardPoints.used);
    }
  }, []);

  useEffect(() => {
    void getPoints();
  }, [getPoints]);

  useEffect(() => {
    if (availablePoints) {
      const availableInNumber = parseFloat(availablePoints);

      setIsDisabled(availableInNumber === 0);
    }
  }, [availablePoints]);

  useEffect(() => {
    if (isExceeded) {
      setErrorMessage(
        "Cannot apply an amount greater than ticket total or available points."
      );
    } else {
      setErrorMessage(undefined);
    }
  }, [isExceeded, isZeroValue]);

  if (!isTaoEnabled) {
    return <></>;
  }

  return (
    <Styled.Container direction="column">
      <Styled.Row>
        <Styled.Column direction="column" role="note">
          <VendorRewardsIcon />
        </Styled.Column>
        <Styled.Column className="rewards-info" direction="column" role="note">
          <span>Rewards Dollars</span>
          <span>${availablePoints ?? "0.00"} available</span>
        </Styled.Column>
        <Styled.Column className="center-items" direction="column">
          <StyledSwitch
            disabled={isDisabled}
            checked={(!!usedPoints && usedPoints !== "0.00") || selected}
            id="switch-pay-points"
            onClick={handleSwitch}
          />
        </Styled.Column>
      </Styled.Row>
      {(selected || !!usedPoints) && (
        <>
          <Styled.Row className="reward-points-row">
            <Styled.Column>
              <AmountInput
                disabled={isDisabled || !!usedPoints}
                id="reward-points-usd"
                defaultValue={usedPoints ?? "0.00"}
                onChange={handleRewardPoints}
              />
            </Styled.Column>
            <Styled.Column>
              <Button
                className={usedPoints ? "points-applied" : ""}
                disabled={
                  isDisabled || isExceeded || isZeroValue || !!usedPoints
                }
                isLoading={isRedeemingRewardDollars}
                text={usedPoints ? "Applied" : "Apply"}
                variant="outlined"
                onClick={handleApplyRewardPoints}
              />
            </Styled.Column>
          </Styled.Row>
          <Styled.Row className="terms" direction="column">
            {errorMessage && <div className="exceeded">{errorMessage}</div>}
            <div>
              Enter the amount of Rewards Dollars you would like to redeem.
              Redemption cannot exceed the subtotal amount (${subtotal}). Your
              selection is final.
            </div>
          </Styled.Row>
        </>
      )}
    </Styled.Container>
  );
}

export default TabPaytronixRewards;
