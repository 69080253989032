function formatUSPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) {
    return "";
  }

  const cleanedNumber = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters

  if (cleanedNumber.length === 10) {
    return `+1 (${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(
      3,
      6
    )}-${cleanedNumber.slice(6)}`;
  } else if (cleanedNumber.length === 11 && cleanedNumber.charAt(0) === "1") {
    return `+1 (${cleanedNumber.slice(1, 4)}) ${cleanedNumber.slice(
      4,
      7
    )}-${cleanedNumber.slice(7)}`;
  } else {
    return "Invalid phone number";
  }
}

export default formatUSPhoneNumber;
