import * as Styled from "./TabActionSheet.styles";

import { BottomSheet } from "react-spring-bottom-sheet";
import BouncingChevronDown from "@component/Icons/BouncingChevronDown";
import Button from "@component/Button/Button";

interface TabActionSheetProps {
  isActionSheetOpen: boolean;
  onActionSheetOpen: () => void;
  onPerformAction: () => void;
  isActionLoading: boolean;
  title?: string;
  tabNumber?: string;
  message?: string;
  actionText: string;
  customIcon?: React.ReactNode;
}

function TabActionSheet({
  isActionSheetOpen,
  onActionSheetOpen,
  onPerformAction,
  isActionLoading,
  title,
  tabNumber,
  message,
  actionText,
  customIcon,
}: TabActionSheetProps) {
  return (
    <BottomSheet
      id="bottom-sheet"
      open={isActionSheetOpen}
      onDismiss={onActionSheetOpen}
    >
      <Styled.Container direction="column">
        {title && (
          <Styled.Header direction="column">
            {customIcon ?? <BouncingChevronDown />}
            <Styled.Title>{title}</Styled.Title>
            {tabNumber && <Styled.TabNumber>{tabNumber}</Styled.TabNumber>}
          </Styled.Header>
        )}
        {message && <Styled.Message>{message}</Styled.Message>}
        <Styled.Buttons>
          <Button
            className="black-btn-outline"
            disabled={isActionLoading}
            text="Cancel"
            variant="outlined"
            onClick={onActionSheetOpen}
          />
          <Button
            className="black-btn"
            isLoading={isActionLoading}
            text={actionText}
            onClick={onPerformAction}
          />
        </Styled.Buttons>
      </Styled.Container>
    </BottomSheet>
  );
}

export default TabActionSheet;
