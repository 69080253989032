import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  align-items: center;
  margin-bottom: 32px;
  width: 100%;
`;

export const LogoContainer = styled(StyledContainer)``;
