import { colors, fonts } from "@theme/Themes";

import MaskedInput from "react-text-mask";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const FormRow = styled(StyledContainer)`
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 12px;

  &.last-row {
    margin-bottom: 16px;
  }

  small {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
  }

  .MuiFormControl-root {
    flex: 1 1 50%;

    label {
      font-size: 1.6rem;
    }

    > div {
      background-color: ${colors.lighterBackground};
      height: 52px;

      &.braintree-hosted-fields-valid:not(#cardholder-name) {
        border-color: ${colors.border};
        color: ${colors.text};
      }
    }

    &:nth-child(1) > div {
      border-right-width: 1px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:nth-child(2) > div {
      border-left-width: 1px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &:only-child > div {
      border-radius: 6px;
      border-width: 2px;
    }
  }

  &.double-top {
    .MuiFormControl-root {
      &:nth-child(1) > div {
        border-bottom-width: 1px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.double-bottom {
    margin-top: -12px;
    .MuiFormControl-root {
      > div {
        border-top-width: 1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-width: 2px;
      }

      &:first-child {
        > div {
          border-left-width: 2px;
          border-right-width: 1px;
        }
      }

      &:last-child {
        > div {
          border-left-width: 1px;
          border-right-width: 2px;
        }
      }
    }
  }

  &.double-simple {
    border: 2px solid ${colors.border};
    border-radius: 6px;
    overflow: hidden;

    > input {
      width: 50%;
      &:first-child {
        border-right: 2px solid ${colors.border};
      }
    }

    &.error {
      border-color: ${colors.error};
      > input {
        border-color: ${colors.error};
      }
    }
  }

  &.validation-errors {
    .MuiFormControl-root {
      > div,
      > div.braintree-hosted-fields-valid:not(#cardholder-name) {
        border-color: ${colors.error} !important;
      }
    }
  }
`;

export const SimpleInput = styled.input`
  border: none;
  background-color: ${colors.lighterBackground};
  font-family: ${fonts.textFont};
  font-size: 20px;
  font-weight: 500;
  padding: 12px;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: ${colors.success} !important;
    background-color: ${colors.lighterBackground} !important;
  }
`;

export const Label = styled.label`
  color: ${colors.text};
  font-family: ${fonts.textFont};
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 8px;
`;

export const FullNameErrorMessage = styled.span`
  color: ${colors.error};
  font-size: 1.6rem;
  margin-top: -8px;
`;
