import { create } from "@incodetech/welcome";
import { environment } from "@utils/constants";

const incodeConfig = {
  apiURL: environment.incodeApiUrl ?? "",
  apiKey: environment.incodeApiKey ?? "",
};

let incode: ReturnType<typeof create> | null = null;

if (incodeConfig.apiURL && incodeConfig.apiKey) {
  incode = create(incodeConfig) ?? undefined;
} else {
  console.warn("This website doesn't support incode onboarding.");
}

export default incode;


