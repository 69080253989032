import BouncingChevronDown from "@component/Icons/BouncingChevronDown";
import TabActionSheet from "@component/TabActionSheet/TabActionSheet";

interface VendorFeedActionSheetProps {
  isActionSheetOpen: boolean;
  onActionSheetIsOpen: () => void;
  onCloseTab: () => void;
  tabNumber?: string;
  isLoading: boolean;
}

function VendorFeedActionSheet({
  isActionSheetOpen,
  onActionSheetIsOpen,
  onCloseTab,
  tabNumber,
  isLoading,
}: VendorFeedActionSheetProps) {
  return (
    <TabActionSheet
      isActionSheetOpen={isActionSheetOpen}
      onActionSheetOpen={onActionSheetIsOpen}
      onPerformAction={() => {
        void onCloseTab();
      }}
      title="Do you really want to close your tab?"
      message="At Bressi you can keep your tab open all night. Leave, come back and your tab will be open. And if you forget, we'll close it out automatically the next day"
      actionText="Confirm"
      tabNumber={tabNumber}
      isActionLoading={isLoading}
      customIcon={<></>}
    />
  );
}

export default VendorFeedActionSheet;
