import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Wrapper = styled(StyledContainer)`
  hr {
    border-color: ${colors.borderDark};
    margin: 22px 0 22px -58px;
    width: calc(100% + 116px);
  }
`;

export const Container = styled(StyledContainer)`
  align-items: center;
  margin: auto auto;
  max-width: 280px;
  text-align: center;
  padding: 20px 0;

  p {
    line-height: 22px;
    margin: 16px 0 0;
  }
`;

export interface CheckIconColorProps {
  outerBackgroundColor?: string;
  innerBackgroundColor?: string;
  size: number;
}

export const CheckIconContainer = styled(StyledContainer)<CheckIconColorProps>`
  align-items: center;
  background-color: ${(props) =>
    props.outerBackgroundColor ?? colors.successLight};
  border-radius: 50%;
  height: ${({ size }) => `${size}px`};
  justify-content: center;
  position: relative;
  width: ${({ size }) => `${size}px`};

  &:before {
    background-color: ${(props) =>
      props.innerBackgroundColor ?? colors.success};
    border-radius: 50%;
    content: "";
    height: ${({ size }) => `calc(${size}px - 25%)`};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${({ size }) => `calc(${size}px - 25%)`};
    z-index: 0;
  }

  svg {
    fill: #ffffff;
    height: ${({ size }) => `calc(${size}px - 50%)`};
    position: relative;
    width: ${({ size }) => `calc(${size}px - 50%)`};
    z-index: 1;
  }
`;

export const Title = styled.h2`
  color: ${colors.success};
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  margin: 16px 0 12px;
`;

export const TabNumber = styled.span`
  font-size: 6rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4px;
  margin: 16px 0;
`;

export const ButtonContainer = styled(StyledContainer)`
  gap: 10px;
`;

export const ProfileImgContainer = styled(StyledContainer)`
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  position: relative;

  img {
    border-radius: 50%;
    height: 140px;
    width: 140px;
  }

  span {
    background-color: ${colors.successDark};
    border-radius: 6px;
    color: ${colors.text};
    font-size: 1.6rem;
    margin-top: -8px;
    padding: 2px 8px;
    width: 100%;

    &.underage {
      background-color: ${colors.error};
    }
  }

  .icon {
    position: absolute;
    height: 42px;
    width: 42px;
    right: -20px;
    top: 50%;
    transform: translateY(-30px);
  }
`;
