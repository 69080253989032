import * as Sentry from "@sentry/browser";
import * as Styled from "./VerifyCode.styles";

import { ChangeEvent, useCallback, useState } from "react";
import { addBreadcrumb, setUser } from "@sentry/react";

import Button from "@component/Button/Button";
import formatUSPhoneNumber from "@utils/formatUSPhoneNumber";
import { isDev } from "@utils/constants";
import { useAuth } from "@context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTab } from "@context/TabContext";
import verifyCodeAndLogin from "@utils/verifyCodeAndLogin";

function VerifyCode() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [validationCode, setValidationCode] = useState<string | undefined>(
    isDev ? "670-114" : undefined
  );
  const [validationError, setValidationError] = useState<string | undefined>();
  const [resendText, setResendText] = useState<string>("Send a new code");

  const { getWebTabPinCode, webTabSession, setWebTabSession, flowId } =
    useAuth();

  const navigate = useNavigate();
  const { placeCode } = useTab();

  const phoneNumber = webTabSession?.phoneNumber;
  const formattedPhoneNumber = formatUSPhoneNumber(phoneNumber);

  const queryClient = useQueryClient();

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValidationCode(event.target.value);
      setValidationError(undefined);
    },
    []
  );

  const handleValidateCode = useCallback(async () => {
    setIsLoading(true);

    if (phoneNumber && validationCode && placeCode) {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `WebTab Login at Place ${placeCode}`,
      });

      try {
        const code = validationCode.replace(/-/g, "");

        const response = await verifyCodeAndLogin({
          number: phoneNumber,
          code,
          placeCode,
        });

        setUser({
          flowId,
          placeCode,
        });

        if (response.errors) {
          if (response.errors.pin) {
            setValidationError(
              `${response.errors.pin}. Request a new code below.`
            );
          } else if (response.errors.phone) {
            setValidationError(response.errors.phone);
          }

          throw new Error("handleValidateCode > Invalid PIN Code.");
        }

        if (!response) {
          setValidationError("An error ocurred. Please try again.");
          setIsLoading(false);
          throw new Error(`handleValidateCode > Request failed: ${response}`);
        }

        setWebTabSession?.({
          ...webTabSession,
          isSessionOpen: true,
        });

        await queryClient.refetchQueries({ active: true });

        return navigate(`/venue/${placeCode}/${response}`, {
          replace: true,
        });
      } catch (error) {
        console.error("Verify code and login request failed:", error);
        Sentry.captureException(error);
      }
    }

    setIsLoading(false);
  }, [
    flowId,
    navigate,
    phoneNumber,
    placeCode,
    queryClient,
    setWebTabSession,
    validationCode,
    webTabSession,
  ]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        void handleValidateCode();
      }
    },
    [handleValidateCode]
  );

  const handleResendPinCode = useCallback(async () => {
    if (phoneNumber) {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `WebTab Pin Code resend at Place ${placeCode}`,
      });

      try {
        setValidationError(undefined);

        const response = await getWebTabPinCode(phoneNumber, true);

        if (response !== "success") {
          setValidationError("An error ocurred. Please try again.");
          throw new Error(`handleResendPinCode > Request failed: ${response}`);
        }

        setResendText("Code sent");

        return setTimeout(() => {
          setResendText("Send a new code");
        }, 20000);
      } catch (error) {
        console.error("Resend PIN Code request failed:", error);
        Sentry.captureException(error);
      }
    }
  }, [getWebTabPinCode, phoneNumber, placeCode]);

  return (
    <div>
      <Styled.Title>Enter the Code</Styled.Title>
      <Styled.FormattedPhoneNumber>
        Sent to {formattedPhoneNumber}
      </Styled.FormattedPhoneNumber>

      <Styled.Container direction="column">
        <Styled.InputContainer>
          <Styled.Input
            mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
            className="form-control"
            placeholder="Enter PIN Code"
            guide={false}
            id="verify-code"
            onBlur={() => {}}
            onChange={(e) => handleTextFieldChange(e)}
            onKeyDown={handleKeyDown}
            value={validationCode}
          />
        </Styled.InputContainer>

        {validationError && (
          <Styled.ErrorMessage>{validationError}</Styled.ErrorMessage>
        )}

        <Button
          color="secondary"
          isLoading={isLoading}
          disabled={!validationCode}
          onClick={() => {
            void handleValidateCode();
          }}
          text="Next"
        />

        <Styled.ResendCode>
          <span>Didn&apos;t get the code?</span>
          <Button
            className={resendText === "Code sent" ? "sent" : ""}
            disabled={resendText === "Code sent"}
            text={resendText}
            onClick={() => {
              void handleResendPinCode();
            }}
            variant="text"
            size="small"
          />
        </Styled.ResendCode>
      </Styled.Container>
    </div>
  );
}

export default VerifyCode;
