import BackgroundImg1 from "@assets/vendor/bressitab/bressi-bg-1.png";
import BackgroundImg2 from "@assets/vendor/bressitab/bressi-bg-2.png";
import GradientBackground from "@assets/vendor/bressitab/bressi-gradient-bg.png";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;

export const LineZeroLogo = styled.img``;

export const HelpContainer = styled.div`
  padding: 0 0 18px;
  width: 100%;

  iframe {
    border: none;
    height: calc(100dvh - 160px);
    width: 100%;
  }

  button {
    background-color: ${colors.background};
    color: ${colors.text};
    display: block;
    margin: 0 auto;
    width: calc(100% - 36px);
  }
`;

export const PageBackground = styled.div`
  background-image: url(${BackgroundImg2});
  background-position: top center;
  background-size: 100% auto !important;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;

  &#phone-bg {
    background-image: url(${BackgroundImg1});
  }

  &#tab-bg.has-items,
  &#overdue-bg,
  &#complete-bg {
    background-image: url(${GradientBackground});
    background-size: auto 200px !important;
    background-position: top center;
    background-repeat: repeat-x;
    background-color: ${colors.paper};
  }
`;
