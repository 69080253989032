import { TabItemData } from "./getTabFeed/getTabFeed";
import { environment } from "./constants";

export interface PaymentMethodData {
  type: string;
  label: string;
  id: string;
  image: string;
  default: true;
}

export interface TabOverdueData {
  hasOverduePayment: boolean;
  amount: string;
  paymentMethods: PaymentMethodData[];
  overdueTabs: [
    {
      name: string;
      number: string;
      subTotal: string;
      total: string;
      tax: string;
      serviceCharges?: string;
      discounts?: string;
      credit?: string;
      tip?: string;
      baseFare?: string;
      items: TabItemData[];
    }
  ];
}

async function getTabOverdue(): Promise<TabOverdueData | undefined> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  try {
    const response = await fetch(`${environment.baseUrl}/overdue`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(
        `getTabOverdue > Request failed with status ${response.status}`
      );
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
}

export default getTabOverdue;
