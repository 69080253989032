import * as Styled from "./RooamCredit.styles";

import { useCallback, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import InfoBottomSheet from "./InfoBottomSheet";
import { colors } from "@theme/Themes";

function InfoModule() {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const handleInfoSheet = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  return (
    <Styled.Card data-testid="rooamCredit-info" onClick={handleInfoSheet}>
      <Styled.Icon>$</Styled.Icon>
      <Styled.InfoText>
        Please select a payment method for any amount over your minimum spend
        credit.
      </Styled.InfoText>
      <BottomSheet
        id="bottom-sheet"
        className="moreMenu-sheet"
        open={isActionSheetOpen}
        onDismiss={handleInfoSheet}
        style={{
          backgroundColor: colors.text,
        }}
      >
        <InfoBottomSheet onActionSheetOpen={handleInfoSheet} />
      </BottomSheet>
    </Styled.Card>
  );
}

export default InfoModule;
