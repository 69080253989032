import { useEffect, useRef } from "react";

import { StepContainer } from "./IdentityVerification.styles";
import incode from "incode";

interface FaceMatchProps {
  session: any;
  onSuccess: () => void;
  onError: (e?: any) => void;
  liveness: any;
  existingUser: any;
  isSecondId: any;
}

function FaceMatch({
  session: token,
  onSuccess,
  liveness,
  existingUser,
  isSecondId,
}: FaceMatchProps) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (incode) {
      incode.renderFaceMatch(containerRef.current, {
        onSuccess,
        token,
        liveness,
        existingUser,
        isSecondId,
      });
    }
  }, [existingUser, isSecondId, liveness, onSuccess, token]);

  if (!incode) {
    return null;
  }

  return <StepContainer ref={containerRef} />;
}

export default FaceMatch;
