import * as Sentry from "@sentry/browser";
import * as Styled from "./TabPaid.styles";

import { CircularProgress, Divider, Zoom } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import Button from "@component/Button/Button";
import { ReactComponent as CheckIcon } from "@vendor/assets/check-icon.svg";
import EmailReceipt from "@component/EmailReceipt/EmailReceipt";
import TabSummary from "@component/TabSummary/TabSummary";
import { colors } from "@theme/Themes";
import useLogOut from "@utils/useLogOut";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

function TabPaid() {
  const [isLoading, setIsLoading] = useState(false);

  const { getTabNumber, selectedTip } = useTab();

  const navigate = useNavigate();
  const {
    placeCode,
    receipt: receiptData,
    isOverdue,
    isReceiptLoading,
  } = useTab();

  const { isLoginOut, handleLogOut } = useLogOut();

  const receipt = receiptData?.data?.receipt;
  const receiptTip =
    receiptData?.data?.receipt.tip ?? selectedTip?.amount?.toString() ?? "0.00";
  const receiptItems = receiptData?.data?.items;

  const handleOpenTab = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await getTabNumber();

      if (data.tabNumber) {
        setTimeout(() => {
          navigate(`/venue/${placeCode}/tab`, {
            replace: true,
          });
        }, 300);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Get Tab Number request failed:", error);
      Sentry.captureException(error);

      setIsLoading(false);
    }
  }, [getTabNumber, navigate, placeCode]);

  useEffect(() => {
    if (isOverdue) {
      navigate(`/venue/${placeCode}/overdue`, {
        replace: true,
      });
    }
  }, [isOverdue, navigate, placeCode]);

  return (
    <>
      {receipt && !isReceiptLoading && (
        <TabSummary
          collapsible={true}
          // creditApplied={receipt?.creditApplied}
          fees={receipt.fee}
          items={receiptItems}
          partialPayment={receipt.partialPayment}
          serviceCharges={receipt.serviceCharges}
          subtotal={receipt.subTotal}
          tax={receipt.tax}
          tip={receiptTip}
          total={receipt.total}
        />
      )}

      {isReceiptLoading && (
        <Styled.ReceiptLoader className="receipt-loader">
          Getting Tab Details <CircularProgress size={20} />
        </Styled.ReceiptLoader>
      )}

      {!isReceiptLoading && !receipt && (
        <Styled.ReceiptLoader className="receipt-loader">
          Tab Details not available
        </Styled.ReceiptLoader>
      )}

      <Styled.Container direction="column">
        <Styled.CheckIconMainContainer className="row-icon" direction="column">
          <Zoom in={true}>
            <Styled.CheckIconContainer fillColor={colors.lighterBackground}>
              <CheckIcon />
            </Styled.CheckIconContainer>
          </Zoom>
          <h4 id="payment-completed">Payment Complete</h4>
        </Styled.CheckIconMainContainer>

        <Divider />

        <Styled.OpenTabButton
          isLoading={isLoading}
          // disabled={isReceiptLoading}
          color="secondary"
          className="overwrite"
          text="Open Another Tab"
          onClick={() => {
            void handleOpenTab();
          }}
        />

        <Button
          text="Log Out"
          isLoading={isLoginOut}
          onClick={() => {
            void handleLogOut();
          }}
          variant="outlined"
        />

        <EmailReceipt isWebTab={true} />
      </Styled.Container>
    </>
  );
}

export default TabPaid;
