import PlaceProvider from "context/PlaceContext";
import ProviderTreeBuilder from "@context/ProviderTreeBuilder";
import TabProvider from "@context/TabContext";

interface ProvidersProps {
  children: React.ReactNode;
}

function Providers({ children }: ProvidersProps) {
  const ProviderTree = ProviderTreeBuilder([[PlaceProvider], [TabProvider]]);
  return <ProviderTree>{children}</ProviderTree>;
}

export default Providers;
