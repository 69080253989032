import braintree, { Client } from "braintree-web";

import { getTabToken } from "./getTabToken";

const createInstance = async (authorization: string) => {
  let instance;
  return await new Promise<Client>((resolve, reject) => {
    braintree.client.create(
      {
        authorization,
      },
      (err, clientInstance) => {
        if (err) {
          console.error("Error creating Braintree instance", err);
          reject(new TypeError("Error generating instance"));
        }

        instance = clientInstance;
        resolve(instance);
      }
    );
  });
};

async function getBraintreeInstance() {
  const authorization = (await getTabToken()).braintreeToken;

  const instance = await createInstance(authorization);

  return {
    authorization,
    instance,
  };
}

export default getBraintreeInstance;
