import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  gap: 10px;
  margin-top: 16px;
`;

export const InnerContainer = styled(StyledContainer)`
  .MuiRadio-root {
    margin-left: -10px;
  }
`;

export const TermsContainer = styled.div`
  color: ${colors.textSecondary};
  font-size: 1.5rem;

  a {
    color: ${colors.text};
    text-decoration: underline;

    &:hover {
      color: ${colors.textDisabled};
    }
  }
`;
