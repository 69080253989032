import * as Styled from "./TabTips.styles";

import { useCallback, useState } from "react";

import TipInput from "@component/Inputs/TipInput/TipInput";
import { TipProps } from "@utils/getTabTips";
import { setItem } from "@hooks/useCookies";
import { useTab } from "@context/TabContext";

function TabTips({ noTipSelected }: { noTipSelected: boolean | undefined }) {
  const [inputVal, setInputVal] = useState<string | undefined>(undefined);
  const [showNoTipError, setShowNoTipError] = useState<boolean | undefined>(
    noTipSelected
  );

  const { feed, selectedTip, tabNumber, tips, updateSelectedTip } = useTab();

  const handleNoTip = useCallback((show: boolean) => {
    setShowNoTipError(show);
  }, []);

  const handleTipSelection = useCallback(
    (event: React.MouseEvent<HTMLElement>, selected: TipProps) => {
      setItem(`userTip_${tabNumber}`, JSON.stringify(selected), 1);

      updateSelectedTip(selected);
      setInputVal(undefined);
      handleNoTip(false);
    },
    [handleNoTip, tabNumber, updateSelectedTip]
  );

  const handleCustomTip = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const tip = parseFloat(event.target.value) * 100;
      const subtotal =
        parseFloat(feed?.totals?.subTotal ?? "0") +
        parseFloat(feed?.totals.discount ?? "0");

      const percentage = tip / subtotal;
      const amount =
        event.target.value.length > 0 ? parseFloat(event.target.value) : 0;

      const newTip = {
        percentage,
        amount,
      };

      const inputValInCurrency = event.target.value;

      setItem(`userTip_${tabNumber}`, JSON.stringify(newTip), 1);

      updateSelectedTip(newTip);
      setInputVal(inputValInCurrency);
      handleNoTip(false);
    },
    [
      feed?.totals.discount,
      feed?.totals?.subTotal,
      handleNoTip,
      tabNumber,
      updateSelectedTip,
    ]
  );

  return (
    <Styled.Container
      className={showNoTipError ? "error" : ""}
      direction="column"
      id="tip-selection"
    >
      <Styled.Title id="select-your-tip" className="tips-title">
        <a href="#select-your-tip">Select Your Tip</a>
      </Styled.Title>
      <Styled.ToggleList
        size="small"
        value={selectedTip}
        exclusive
        onChange={handleTipSelection}
      >
        {tips?.tips.map((tip, index) => {
          const isSelected = tip.percentage === selectedTip?.percentage;

          if (tip.percentage < 0) {
            return null;
          }

          return (
            <Styled.TipButton
              className={`overwrite tip-btn ${isSelected && "selected"}`}
              key={`tip-option-${index}`}
              value={tip}
              selected={isSelected}
              id={`tip-option-${tip.percentage}`}
            >
              {tip.percentage === 0 && (
                <>
                  <strong className="smaller">No Tip</strong>
                </>
              )}
              {tip.percentage > 0 && (
                <>
                  <strong>{tip.percentage}%</strong>
                  <span>${tip.amount?.toFixed(2)}</span>
                </>
              )}
            </Styled.TipButton>
          );
        })}
      </Styled.ToggleList>
      <TipInput
        onChange={handleCustomTip}
        resetInput={inputVal === undefined}
        defaultValue={inputVal ?? "Enter Custom Tip Amount"}
      />
      {showNoTipError && (
        <span className="no-tip-message">Select your tip</span>
      )}
    </Styled.Container>
  );
}

export default TabTips;
