import {
  Container as TipsContainer,
  TipButton as TipsTipButton,
  Title as TipsTitle,
  ToggleList as TipsToggleList,
} from "@component/Tips/Tips.styles";

import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(TipsContainer)``;

export const Title = styled(TipsTitle)``;

export const TipButton = styled(TipsTipButton)`
  &.overwrite {
    strong {
      font-size: 2.2rem;
      line-height: 2.2rem;

      &.smaller {
        font-size: 1.5rem;
      }
    }

    span {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    &.Mui-selected {
      background-color: #ffffff !important;
      border: none !important;
    }
  }
`;

export const ToggleList = styled(TipsToggleList)``;
