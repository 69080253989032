import { environment } from "./constants";
import getNextScreen from "./getNextScreen";

interface VerifyCodeAndLoginProps {
  number: string;
  code: string;
  placeCode: string;
}

async function verifyCodeAndLogin({
  number,
  code,
  placeCode,
}: VerifyCodeAndLoginProps) {
  const url = `${environment.baseUrl}/login`;

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);

  try {
    const xRooamSession = localStorage.getItem("x-rooam-session");

    if (!xRooamSession) {
      console.error("Session token not found");
      throw new Error("verifyCodeAndLogin > Session token not found");
    }

    headers.append("x-rooam-session", xRooamSession);

    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({
        number,
        code,
        placeCode,
      }),
    });

    if (!response.ok) {
      const responseError = await response.json();

      if (
        responseError.errors.pin?.includes(
          "Invalid pin number, SMS Code Invalidated"
        ) ||
        responseError.errors.phone.includes(
          "Please wait 3 seconds and try again"
        )
      ) {
        return responseError;
      }

      throw new Error(
        `verifyCodeAndLogin > Request failed with status ${response.status}`
      );
    }

    const newSessionToken = response.headers.get("x-rooam-session");

    // Validate if sessionToken is not null before returning
    if (newSessionToken === null) {
      throw new Error("verifyCodeAndLogin > Failed to get new session token");
    }

    localStorage.setItem("x-rooam-session", newSessionToken);

    return await getNextScreen();
  } catch (error) {
    console.error(error);
    throw new Error("verifyCodeAndLogin > Failed to validate PIN Code");
  }
}

export default verifyCodeAndLogin;
