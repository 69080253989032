import React, { useEffect } from "react";

import { QueryClient } from "react-query";
import TabVoided from "@component/TabVoided/TabVoided";
import { queryKeys } from "@utils/constants";
import usePageTitle from "@hooks/usePageTitle";

interface VoidedProps {
  queryClient: QueryClient;
}

function Voided({ queryClient }: VoidedProps) {
  usePageTitle("Tab Voided");

  useEffect(() => {
    queryClient.removeQueries(queryKeys.tabFeed);
  }, [queryClient]);

  return <TabVoided />;
}

export default Voided;
