import * as Styled from "./TabLiveFeed.styles";

import { useCallback, useEffect, useState } from "react";

import Button from "@component/Button/Button";
import ComponentLoader from "@component/Loading/ComponentLoader";
import OrderList from "@component/Order/OrderList";
import RooamCredit from "@component/RooamCredit/RooamCredit";
import TabPaytronixRewards from "@component/TabPaytronixRewards/TabPaytronixRewards";
import TabSummary from "@component/TabSummary/TabSummary";
import TabTips from "@component/TabTips/TabTips";
import VendorEmptyTab from "@vendor/components/VendorEmptyTab/VendorEmptyTab";
import VendorFeedActionSheet from "@vendor/components/VendorFeedActionSheet/VendorFeedActionSheet";
import { useNavigate } from "react-router-dom";
import useRooamCredit from "@hooks/useRooamCredit";
import { useTab } from "@context/TabContext";

function TabLiveFeed() {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noTipSelected, setNoTipSelected] = useState<boolean | undefined>();
  const [appliedDiscount, setAppliedDiscount] = useState<string | undefined>();

  const navigate = useNavigate();

  const {
    closeOpenTab,
    feed,
    getTabNumber,
    isFeedLoading,
    placeCode,
    selectedTip,
    tabNumber,
    tabTotal,
  } = useTab();

  const { hasRooamCredit, totalAfterCredit } = useRooamCredit();

  const items = feed?.tab?.items;

  useEffect(() => {
    if (!tabNumber) {
      void getTabNumber();
    }
  }, [getTabNumber, tabNumber]);

  const handleActionSheetIsOpen = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  const handleCloseTab = useCallback(async () => {
    setIsLoading(true);
    setNoTipSelected(false);

    if (!selectedTip) {
      setIsLoading(false);
      return setNoTipSelected(true);
    }

    handleActionSheetIsOpen();

    const nextScreen = await closeOpenTab({
      tip: selectedTip?.amount?.toFixed(2) ?? "0",
    });

    if (nextScreen) {
      navigate(`/venue/${placeCode}/${nextScreen.next.toLowerCase()}`, {
        replace: true,
      });
    }

    setIsLoading(false);
  }, [closeOpenTab, handleActionSheetIsOpen, navigate, placeCode, selectedTip]);

  useEffect(() => {
    if (feed?.totals.discount !== "0.00") {
      setAppliedDiscount(feed?.totals.discount);
    } else {
      setAppliedDiscount(undefined);
    }
  }, [feed?.totals.discount]);

  const handleDiscountApplied = useCallback((discountToApply: string) => {
    setAppliedDiscount(discountToApply);
  }, []);

  if (!feed?.open || isFeedLoading || !placeCode || !tabNumber) {
    return <ComponentLoader />;
  }

  if (items?.length === 0) {
    return <VendorEmptyTab placeCode={placeCode} tabNumber={tabNumber} />;
  }

  return (
    <Styled.Container direction="column" className="tab-live-feed">
      <Styled.Title className="tab-number">
        <strong>{feed?.tab?.tabNumber}</strong>
        <span>Tab Number</span>
      </Styled.Title>
      {items && <OrderList items={items} />}

      <TabTips noTipSelected={noTipSelected} />

      <TabPaytronixRewards onDiscountApplied={handleDiscountApplied} />

      {hasRooamCredit && <RooamCredit />}

      <TabSummary
        collapsible={false}
        discount={appliedDiscount}
        fees={feed?.totals?.fee}
        partialPayment={feed?.totals?.partialPayment}
        serviceCharges={feed?.totals?.serviceCharges}
        subtotal={feed?.totals?.subTotal}
        tax={feed?.totals?.tax}
        tip={selectedTip?.amount?.toFixed(2) ?? "0.00"}
        total={tabTotal ?? "0.00"}
      />

      {/* TO-DO: create a global totalAfterCredit */}
      {tabTotal && (
        <Styled.ButtonContainer direction="column" className="btn-container">
          <Button
            isLoading={isLoading}
            color="secondary"
            node={<span>Close Tab: ${totalAfterCredit ?? tabTotal}</span>}
            onClick={() => {
              void handleActionSheetIsOpen();
            }}
          />
          <Styled.OpenTabDisclaimer>
            Tabs left open will auto-close with a 20% tip.
          </Styled.OpenTabDisclaimer>
        </Styled.ButtonContainer>
      )}
      <VendorFeedActionSheet
        isActionSheetOpen={isActionSheetOpen}
        onActionSheetIsOpen={handleActionSheetIsOpen}
        onCloseTab={() => {
          void handleCloseTab();
        }}
        tabNumber={tabNumber}
        isLoading={isLoading}
      />
    </Styled.Container>
  );
}

export default TabLiveFeed;
