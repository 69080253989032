import { colors, fonts } from "@theme/Themes";

import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Card = styled.button`
  align-items: start;
  background-color: ${colors.lighterBackground};
  border: 1px solid ${colors.borderDark};
  border-radius: 8px;
  color: ${colors.text};
  display: flex;
  font-family: ${fonts.textFont};
  gap: 12px;
  margin: 16px 0 20px;
  padding: 24px 16px;

  > * {
    font-family: ${fonts.textFont};
  }
`;

export const Icon = styled(StyledContainer)`
  align-items: center;
  background-color: ${colors.success};
  border-radius: 8px;
  color: ${colors.text};
  flex-shrink: 0;
  flex-grow: 0;
  font-family: ${fonts.titlesFont};
  font-size: 24px;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  width: 36px !important;

  &.big {
    font-size: 32px;
    line-height: 1;
    height: 48px;
    width: 48px;
  }
`;

export const InfoText = styled.p`
  font-family: ${fonts.titlesFont};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0;
  text-align: left;
`;

export const InfoBottomSheetContainer = styled(StyledContainer)`
  align-items: center;
  color: ${colors.textDisabled};
  line-height: 1.25;
  text-align: center;
  padding: 0 32px 32px;

  h3 {
    color: ${colors.background};
  }

  p {
    margin: 0 0 16px;

    &:last-child {
      margin-top: 16px;
      a {
        color: ${colors.textDisabled};
        text-decoration: underline;
      }
    }
  }

  button {
    &.black-btn {
      background-color: ${colors.background};
      color: ${colors.text};

      &:disabled {
        color: inherit !important;
      }
    }
  }
`;

export const TextSection = styled(StyledContainer)`
  align-items: start;
  flex-grow: 1;
  font-family: ${fonts.textFont};
  font-size: 1.2rem;
  justify-content: start;
  /* font-weight: 600;
  margin: 0; */

  > * {
    margin: 0;
  }

  h4 {
    font-weight: 600;
    font-size: 1.7rem;
  }

  p {
    color: ${colors.textSecondary};
  }
`;

export const Credits = styled.span`
  color: ${colors.success};
  font-weight: 600;
  font-size: 1.7rem;
`;
