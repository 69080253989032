import {
  Column as PaytronixColumn,
  PaytronixRewards,
  Row as PaytronixRow,
} from "components/PaytronixRewards/PaytronixRewards.styles";

import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(PaytronixRewards)`
  margin-bottom: 32px;

  .MuiSwitch-root {
    .MuiSwitch-switchBase {
      .MuiSwitch-thumb {
        color: ${colors.borderLight};
      }

      &.Mui-checked .MuiSwitch-thumb {
        color: ${colors.paper};
      }

      &.Mui-disabled {
        .MuiSwitch-thumb {
          color: ${colors.paper};
        }

        + .MuiSwitch-track {
          opacity: 0.8 !important;
        }
      }
    }
  }
`;

export const Row = styled(PaytronixRow)``;

export const Column = styled(PaytronixColumn)`
  button.points-applied {
    color: ${colors.success};
    border-color: ${colors.success};
  }
`;
