import { VerificationStatus } from "@component/IdentityVerification/Steps";
import { addBreadcrumb } from "@sentry/react";
import { environment } from "./constants";
import getNextScreen from "./getNextScreen";

interface UpdateTabIdentityProfileProps {
  id: string;
  state: VerificationStatus;
  ocrData: any;
  base64FaceImage: string;
}

async function updateTabIdentityProfile({
  id,
  state,
  ocrData,
  base64FaceImage,
}: UpdateTabIdentityProfileProps) {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  const body = JSON.stringify({
    id,
    state,
    provider: "INCODE",
    firstName: ocrData?.name?.firstName,
    lastName: ocrData?.name?.lastName,
    birthdateTimestamp: ocrData?.birthDate?.toString(),
    base64FaceImage,
  });

  try {
    addBreadcrumb({
      level: "info",
      category: "user_action",
      message: "Updating Identity Profile",
    });

    const response = await fetch(
      `${environment.baseUrl}/identity/verification/profile`,
      {
        method: "POST",
        headers,
        body,
      }
    );

    if (!response.ok) {
      throw new Error(
        `updateTabIdentityProfile > Updating Identity Profile request failed with status ${response.status}`
      );
    }

    return await getNextScreen();
  } catch (error) {
    console.error(
      "updateTabIdentityProfile > Error updating User Identity Verification Profile",
      error
    );
  }
}

export default updateTabIdentityProfile;
