import calculateTabTips from "./calculateTabTips";
import { environment } from "./constants";

export interface TipProps {
  percentage: number;
  amount?: number;
}

export interface TipsData {
  tips: TipProps[];
  defaultTip: TipProps;
}

async function getTabTips(subtotal: string): Promise<TipsData | undefined> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  try {
    const response = await fetch(`${environment.baseUrl}/tab`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(
        `getTabTips > Request failed with status ${response.status}`
      );
    }

    const data: TipsData = await response.json();

    const tips = calculateTabTips(subtotal, data?.tips);

    return {
      tips,
      defaultTip: data.defaultTip,
    };
  } catch (error) {
    console.error(error);
  }
}

export default getTabTips;
