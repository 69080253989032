import AmericanImg from "@vendor/assets/images/payments/amex.jpg";
import AppleImg from "@vendor/assets/images/payments/apple-pay.jpg";
import DinersImg from "@vendor/assets/images/payments/diners-club.jpg";
import DiscoverImg from "@vendor/assets/images/payments/discover.jpg";
import GoogleImg from "@vendor/assets/images/payments/google-pay.jpg";
import JCBImg from "@vendor/assets/images/payments/jcb.jpg";
import MaestroImg from "@vendor/assets/images/payments/maestro.jpg";
import MasterImg from "@vendor/assets/images/payments/mastercard.jpg";
import OtherImg from "@vendor/assets/images/payments/other.jpg";
import VisaImg from "@vendor/assets/images/payments/visa.jpg";

interface Payment {
  type?: string;
  image?: string;
}

const paymentImageMap: { [key: string]: string } = {
  LOADING: OtherImg,
  AP: AppleImg,
  APPLE_PAY: AppleImg,
  GOOGLE_PAY: GoogleImg,
  GP: GoogleImg,
  visa: VisaImg,
  american: AmericanImg,
  apple: AppleImg,
  google: GoogleImg,
  android: GoogleImg,
  master: MasterImg,
  discover: DiscoverImg,
  diners: DinersImg,
  maestro: MaestroImg,
  jcb: JCBImg,
};

const getImageFromPayment = (payment: Payment): string => {
  // Fallback to 'LOADING' if type or image is not provided
  const paymentType = payment.type ?? "LOADING";
  const paymentImage = payment.image ?? "LOADING";

  // Check payment type first
  if (paymentImageMap[paymentType]) {
    return paymentImageMap[paymentType];
  }
  // Check if image string contains any of the keys
  for (const key in paymentImageMap) {
    if (paymentImage.toLowerCase().includes(key.toLowerCase())) {
      return paymentImageMap[key];
    }
  }
  // Default image if no match is found
  return paymentImageMap.LOADING;
};

export default getImageFromPayment;
