import VerifyCode from "@component/VerifyCode/VerifyCode";
import { useEffect } from "react";
import usePageTitle from "@hooks/usePageTitle";

function Verify() {
  usePageTitle("Verify Your Phone Number");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <VerifyCode />;
}

export default Verify;
