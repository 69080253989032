import { addBreadcrumb } from "@sentry/react";
import { environment } from "./constants";

async function updateTabUserAccount(first: string, last: string) {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  const body = JSON.stringify({
    first,
    last,
  });

  try {
    addBreadcrumb({
      level: "info",
      category: "user_action",
      message: "Updating WebTab account",
    });

    const response = await fetch(`${environment.baseUrl}/account`, {
      method: "POST",
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error(
        `updateTabUserAccount > Request failed with status ${response.status}`
      );
    }

    return;
  } catch (error) {
    console.error(error);
  }
}

export default updateTabUserAccount;
