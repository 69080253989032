import TabLiveFeed from "@component/TabLiveFeed/TabLiveFeed";
import { useEffect } from "react";
import usePageTitle from "@hooks/usePageTitle";

function Tab() {
  usePageTitle("Your Tab");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <TabLiveFeed />;
}

export default Tab;
