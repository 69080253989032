import * as Styled from "./TabPaymentMethodList.styles";

import { useCallback, useEffect, useState } from "react";

import Button from "@component/Button/Button";
import CheckIcon from "components/Icons/CheckIcon";
import Radio from "components/Inputs/Radio/Radio";
import getImageFromPayment from "@utils/getImageFromPayment";
import openTab from "@utils/openTab";
import updateTabPaymentMethod from "@utils/updateTabPaymentMethod";
import { useAuth } from "@context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

export interface PaymentMethodProps {
  type: string;
  label: string;
  id: string;
  image: string;
  default: boolean;
}

interface TabPaymentMethodListProps {
  isOverdue?: boolean;
}

function TabPaymentMethodList({ isOverdue }: TabPaymentMethodListProps) {
  const [isLoading, setIsLoading] = useState<boolean | undefined>();

  const { webTabSession, setWebTabSession } = useAuth();

  const {
    placeCode,
    paymentMethods,
    getTabNumber,
    refreshPaymentMethods,
    selectedPaymentMethod,
    setPaymentMethods,
    setSelectedPaymentMethod,
  } = useTab();

  const navigate = useNavigate();

  useEffect(() => {
    refreshPaymentMethods();
  }, [refreshPaymentMethods]);

  // set Selected Method if there's a default method
  useEffect(() => {
    if (!selectedPaymentMethod) {
      const defaultMethod = paymentMethods?.filter((item) => item.default)[0];
      setSelectedPaymentMethod(defaultMethod);

      if (webTabSession && setWebTabSession) {
        setWebTabSession({
          ...webTabSession,
          selectedPaymentMethod: defaultMethod,
        });
      }
    }
    // 🚨 Don't add webTabSession to the dependency array to avoid multiple re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods, selectedPaymentMethod, setWebTabSession]);

  const handleAddNewPayment = useCallback(() => {
    navigate(`/venue/${placeCode}/addpayment`);
  }, [navigate, placeCode]);

  const handleConnect = useCallback(() => {
    void getTabNumber();

    navigate(`/venue/${placeCode}/tab`, {
      replace: true,
    });
  }, [navigate, placeCode, getTabNumber]);

  const handleSelectMethod = useCallback(
    (id: string, paymentType: string) => {
      setIsLoading(true);

      const updatedPaymentMethods = paymentMethods;
      updatedPaymentMethods?.map((method) => {
        method.default = method.id === id;
        return method;
      });

      setPaymentMethods(updatedPaymentMethods);

      const newSelectedPaymentMethod = updatedPaymentMethods?.filter(
        (item) => item.default
      )[0];

      if (webTabSession && setWebTabSession) {
        setWebTabSession({
          ...webTabSession,
          selectedPaymentMethod: newSelectedPaymentMethod,
        });
      }

      updateTabPaymentMethod({ id, paymentType })
        .then(() => {
          refreshPaymentMethods();
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    [
      paymentMethods,
      refreshPaymentMethods,
      setPaymentMethods,
      setWebTabSession,
      webTabSession,
    ]
  );

  return (
    <Styled.Container className="tab-overdue" direction="column">
      <h3>Select Payment</h3>
      <Styled.List role="list">
        {paymentMethods?.map((method, index) => {
          return (
            <Styled.ListItem
              className="payment-method-list"
              key={`${method.id}_${index}`}
              role="listitem"
            >
              <Styled.Card>
                <Styled.CardContainer className="card-type-container">
                  <Styled.PaymentMethodIcon
                    className="card-type"
                    width={32}
                    src={getImageFromPayment({
                      type: method?.type,
                      image: method?.image,
                    })}
                    alt="method?.type icon"
                  />
                  <Styled.Label htmlFor={`select-payment-method-${index}`}>
                    {method.label}
                  </Styled.Label>
                  <Radio
                    checked={method.default}
                    checkedIcon={<CheckIcon />}
                    id={`select-payment-method-${index}`}
                    name={`select-payment-method-${index}`}
                    onChange={() => handleSelectMethod(method.id, method.type)}
                    value={index}
                  />
                </Styled.CardContainer>
              </Styled.Card>
            </Styled.ListItem>
          );
        })}
      </Styled.List>
      <Styled.AddNewPaymentButton
        className="overwrite solid-btn"
        onClick={handleAddNewPayment}
        text="Add New Payment"
        type="button"
      />
      {!isOverdue && (
        <Button
          className="btn_background"
          disabled={!selectedPaymentMethod || isLoading}
          isLoading={isLoading}
          text="Connect"
          onClick={handleConnect}
          color="secondary"
        />
      )}
    </Styled.Container>
  );
}

export default TabPaymentMethodList;
