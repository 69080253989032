import * as Styled from "./VendorTopBar.styles";

import { ReactComponent as BressiLogo } from "@vendor/assets/logo.svg";

const VendorTopBar = () => {
  return (
    <Styled.Container direction="column">
      <BressiLogo />
    </Styled.Container>
  );
};

export default VendorTopBar;
