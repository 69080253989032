import * as Styled from "./VendorTerms.styles";

function VendorTerms() {
  return (
    <Styled.TermsCopy>
      By connecting a payment, you agree to the Rooam&apos;s{" "}
      <a href="https://rooam.co/legal" target="_blank" rel="noreferrer">
        Terms & Conditions
      </a>{" "}
      and{" "}
      <a href="https://rooam.co/policy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
    </Styled.TermsCopy>
  );
}

export default VendorTerms;
