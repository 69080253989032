import * as Styled from "./CreateWebTabAccount.styles";

import WebTabHostedFields, {
  FullNameProps,
} from "@component/WebTabHostedFields/WebTabHostedFields";
import braintree, { HostedFields } from "braintree-web";
import { isFalsy, isTruthy } from "@utils/truthy";
import { useCallback, useEffect, useState } from "react";

import { Divider } from "@mui/material";
import Error from "@component/Error/Error";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import ExpressCheckoutWebTab from "@component/ExpressCheckout/ExpressCheckoutWebTab";
import { NetworkError } from "@utils/api/errors";
import VendorTerms from "@vendor/components/VendorTerms/VendorTerms";
import { addBreadcrumb } from "@sentry/react";
import addTabPaymentMethod from "@utils/addTabPaymentMethod";
import createHostedFieldsForm from "./createHostedFieldsForm";
import getTabUserAccount from "@utils/getTabUserAccount";
import trackGaEvent from "@utils/trackGaEvent";
import updateTabUserAccount from "@utils/updateTabUserAccount";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

interface CreateWebTabAccountProps {
  clientInstance: any;
}

function CreateWebTabAccount({ clientInstance }: CreateWebTabAccountProps) {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<
    HostedFields | undefined
  >(undefined);
  const [hostedFieldsCreated, setHostedFieldsCreated] =
    useState<boolean>(false);
  const [dataCollector, setDataCollector] = useState<braintree.DataCollector>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [hasUsername, setHasUsername] = useState<boolean>(true);

  const { placeCode, refreshPaymentMethods } = useTab();
  const navigate = useNavigate();

  const getUserName = useCallback(async () => {
    const name = await getTabUserAccount();

    setHasUsername(!!name?.first && !!name?.last);
  }, []);

  useEffect(() => {
    void getUserName();
  }, [getUserName]);

  const createDataCollector = useCallback(async () => {
    if (clientInstance) {
      await braintree.dataCollector
        .create({ client: clientInstance })
        .then((dataCollectorInstance) => {
          const deviceData = dataCollectorInstance.deviceData;

          setDataCollector(dataCollectorInstance);

          sessionStorage.setItem("deviceData", deviceData);
        })
        .catch((error) =>
          console.error("Error creating Data Collector instance", error)
        );
    }
  }, [clientInstance]);

  useEffect(() => {
    if (clientInstance && !hostedFieldsCreated) {
      createDataCollector().catch((error) =>
        console.error(
          "Add Payment Method > Failure creating Braintree DataCollector",
          error
        )
      );

      setHostedFieldsCreated(true);

      createHostedFieldsForm({
        client: clientInstance,
        setHostedFieldsInstance,
      });
    }
  }, [
    clientInstance,
    createDataCollector,
    hostedFieldsCreated,
    hostedFieldsInstance,
  ]);

  const handleNextScreen = useCallback(
    async (nextScreen: string) => {
      setIsLoading(false);
      navigate(`/venue/${placeCode}/${nextScreen}`, {
        replace: true,
      });
    },
    [navigate, placeCode]
  );

  const handleAddPaymentMethod = useCallback(
    (nonce: string, paymentType: string) => {
      if (nonce && paymentType) {
        addTabPaymentMethod({
          nonce,
          paymentType,
          deviceData: dataCollector?.deviceData,
        })
          .then((nextScreen) => {
            refreshPaymentMethods();
            void handleNextScreen(nextScreen);
          })
          .catch((error) => {
            setError("statusError");
            if (
              error instanceof NetworkError &&
              isTruthy(error.data?.userMessage)
            ) {
              setErrorMessage?.(error.data?.userMessage);
            }
            setIsLoading(false);
          });
      }
    },
    [dataCollector?.deviceData, handleNextScreen, refreshPaymentMethods]
  );

  const handleConnect = useCallback(
    (fullName?: FullNameProps) => {
      setIsLoading(true);
      setError(undefined);
      setErrorMessage(undefined);

      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Add Payment Method at Place ${placeCode}`,
      });

      hostedFieldsInstance?.tokenize(function (err, instancePayload) {
        void (async () => {
          if (err) {
            console.error("Add Payment Method > Error Verifying payment", err);
            setError(err.details?.invalidFieldKeys ?? err?.code);
            setIsLoading(false);

            trackGaEvent("Error: Verify Payment creating token", {
              description: `PlaceCode: ${placeCode} | `,
            });

            return;
          }

          if (instancePayload) {
            handleAddPaymentMethod(instancePayload.nonce, "CREDIT_CARD");

            if (fullName?.firstName && fullName?.lastName) {
              try {
                await updateTabUserAccount(
                  fullName.firstName,
                  fullName.lastName
                );
              } catch (error) {
                console.error("Error updating user account:", error);
              }
            }
          }
        })();
      });
    },
    [handleAddPaymentMethod, hostedFieldsInstance, placeCode]
  );

  return (
    <Styled.Container direction="column">
      <Styled.Title>Connect a Payment.</Styled.Title>

      {error && error === "statusError" && (
        <Error>
          <strong>Error:</strong> There was an error processing your payment.
          Please try again or contact your server.
        </Error>
      )}

      {error && error !== "statusError" && isFalsy(errorMessage) && (
        <Error>
          <strong>Validation Error:</strong> An issue occurred when validating
          your card. Please check below and try again.
        </Error>
      )}

      {error && error !== "statusError" && isTruthy(errorMessage) && (
        <Error>
          <strong>Validation Error:</strong> {errorMessage}
        </Error>
      )}

      <ExpressCheckoutWebTab
        clientInstance={clientInstance}
        onAddPaymentMethod={handleAddPaymentMethod}
      />
      <Divider>
        <span className="divider-text">or</span>
      </Divider>

      <WebTabHostedFields
        error={error}
        hasUsername={hasUsername}
        isLoading={isLoading}
        onConnect={handleConnect}
      />

      <VendorTerms />
    </Styled.Container>
  );
}

export default CreateWebTabAccount;
