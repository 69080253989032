import * as Styled from "./WebTabHostedFields.styles";

import { useCallback, useState } from "react";

import BraintreeField from "@component/BraintreeField/BraintreeField";
import Button from "@component/Button/Button";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";

export interface FullNameProps {
  firstName: string | undefined;
  lastName: string | undefined;
}

interface WebTabHostedFieldsProps {
  error: ErrorType | undefined;
  hasUsername: boolean;
  isLoading: boolean;
  onConnect: (fullName?: FullNameProps) => void;
}

function WebTabHostedFields({
  error,
  hasUsername,
  isLoading,
  onConnect,
}: WebTabHostedFieldsProps) {
  const [fullName, setFullName] = useState<FullNameProps>({
    firstName: undefined,
    lastName: undefined,
  });
  const [nameError, setNameError] = useState<boolean>(false);

  const hasCardError = (errorType: string | undefined) => {
    if (!errorType) {
      return false;
    }

    return (
      errorType.includes("number") ||
      errorType.includes("expirationDate") ||
      errorType.includes("cvv")
    );
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setNameError(false);
    setFullName((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnConnect = useCallback(() => {
    const hasFullName = Boolean(fullName.firstName && fullName.lastName);

    if (!hasUsername && !hasFullName) {
      setNameError(true);
      return;
    }

    onConnect(fullName);
  }, [fullName, hasUsername, onConnect]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleOnConnect();
      }
    },
    [handleOnConnect]
  );

  return (
    <>
      {!hasUsername && (
        <>
          <Styled.Label>Full Name</Styled.Label>
          <Styled.FormRow className={`double-simple ${nameError && "error"}`}>
            <Styled.SimpleInput
              className="form-control"
              placeholder="First Name"
              id="web-tab-first-name"
              name="firstName"
              onBlur={() => {}}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              value={fullName.firstName}
            />
            <Styled.SimpleInput
              className="form-control"
              placeholder="Last Name"
              id="web-tab-last-name"
              name="lastName"
              onBlur={() => {}}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              value={fullName.lastName}
            />
          </Styled.FormRow>
        </>
      )}
      {nameError && (
        <Styled.FullNameErrorMessage>
          Please enter your First Name and Last Name
        </Styled.FullNameErrorMessage>
      )}
      <Styled.FormRow
        className={`fields-group double-top ${
          hasCardError(error) && "validation-errors"
        }`}
      >
        <BraintreeField
          error={hasCardError(error)}
          errorText="..."
          id="cc-number"
          label="Card Information"
          handleErrorsOnParent={true}
        />
      </Styled.FormRow>
      <Styled.FormRow
        className={`fields-group double-bottom ${
          hasCardError(error) && "validation-errors"
        }`}
      >
        <BraintreeField
          error={hasCardError(error)}
          errorText="Enter a valid expiry date."
          id="cc-expiration"
          label="Expires"
          hideLabel={true}
          handleErrorsOnParent={true}
        />
        <BraintreeField
          error={hasCardError(error)}
          errorText="..."
          id="cc-cvv"
          label="CVV"
          hideLabel={true}
          handleErrorsOnParent={true}
        />
      </Styled.FormRow>
      <Styled.FormRow className="last-row">
        <BraintreeField
          error={error?.includes("postalCode") ?? false}
          errorText="Enter a valid Zip Code."
          id="cc-zip"
          label="Zip Code"
        />
      </Styled.FormRow>
      <Button
        className="btn_background"
        color="secondary"
        isLoading={isLoading}
        text="Connect"
        onClick={handleOnConnect}
      />
    </>
  );
}

export default WebTabHostedFields;
