import { addBreadcrumb } from "@sentry/react";
import { environment } from "./constants";

export interface TabUserAccount {
  email: string | null;
  first: string | null;
  last: string | null;
  phone: string;
}

async function getTabUserAccount(): Promise<TabUserAccount | undefined> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  try {
    addBreadcrumb({
      level: "info",
      category: "user_action",
      message: "Getting WebTab account info",
    });

    const response = await fetch(`${environment.baseUrl}/account`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(
        `getTabUserAccount > Request failed with status ${response.status}`
      );
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
}

export default getTabUserAccount;
