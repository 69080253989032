import Button from "@component/Button/Button";
import { ResetPermissionContainer as ErrorContainer } from "./IdentityVerification.styles";
import ErrorIcon from "@mui/icons-material/Error";
import { useCallback } from "react";

function OnboardingError() {
  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ErrorContainer direction="column">
      <h1>There was an error</h1>
      <ErrorIcon fontSize="large" />
      <p>There was an error during the onboarding process. Please try again.</p>
      <Button color="secondary" text="Try Again" onClick={handleReload} />
    </ErrorContainer>
  );
}

export default OnboardingError;
