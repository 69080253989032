import * as Styled from "./RooamCredit.styles";

import Button from "@component/Button/Button";

interface InfoBottomSheetProps {
  onActionSheetOpen: () => void;
}

function InfoBottomSheet({ onActionSheetOpen }: InfoBottomSheetProps) {
  return (
    <Styled.InfoBottomSheetContainer direction="column">
      <Styled.Icon className="big">$</Styled.Icon>
      <h3>F&B Credit</h3>
      <p>
        Thank you for your ticket purchase!
        <br />
        Please register and select a payment method for any amount over your
        minimum spend credit.
      </p>
      <p>F&B credit can be used on multiple tabs only for this event.</p>
      <Button className="black-btn" text="Back" onClick={onActionSheetOpen} />
      <p>
        <a href="https://rooam.co/legal" target="_blank" rel="noreferrer">
          Terms & Conditions
        </a>{" "}
        Apply
      </p>
    </Styled.InfoBottomSheetContainer>
  );
}

export default InfoBottomSheet;
