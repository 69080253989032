import TabPaid from "@component/TabPaid/TabPaid";
import { useEffect } from "react";
import usePageTitle from "@hooks/usePageTitle";

function TabComplete() {
  usePageTitle("Payment Complete");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <TabPaid />;
}

export default TabComplete;
