import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

interface TabPaymentMethodProps {
  hideTitle?: boolean;
}

export const MainContainer = styled(StyledContainer)<TabPaymentMethodProps>`
  color: ${colors.text};
  gap: 16px;
  margin: ${({ hideTitle }) => (hideTitle ? "20px 0 12px" : "32px 0 0")};

  > div {
    margin: ${({ hideTitle }) => hideTitle && "0"};
  }

  h2#payment-method {
    font-size: 1.8rem;
    margin: 0px;

    a {
      color: inherit;
      pointer-events: none;
    }
  }
`;

export const Container = styled(StyledContainer)`
  background-color: ${colors.paper};
  border: 1px solid ${colors.border};
  border-radius: 6px;
  gap: 16px;
  margin: 0 0 32px 0;
  padding: 8px;
`;

export const PaymentMethodIcon = styled.img`
  border-radius: 6px;
`;

export const ChangeButton = styled(Button)`
  &.overwrite {
    margin-left: auto;
    max-width: 60px;
  }
`;
