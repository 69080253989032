import { TipProps } from "./getTabTips";

function calculateTabTips(subtotal: string, placeTips: TipProps[]): TipProps[] {
  const parsedSubtotal = parseFloat(subtotal);
  if (isNaN(parsedSubtotal)) {
    console.warn("Invalid subtotal value:", subtotal);
    return [];
  }

  const tips = placeTips.map((tip) => {
    const amount = parseFloat(subtotal) * (tip.percentage / 100);

    return {
      percentage: tip.percentage,
      amount,
    };
  });

  return tips;
}

export default calculateTabTips;
