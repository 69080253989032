import PhoneNumber from "@component/PhoneNumber/PhoneNumber";
import usePageTitle from "@hooks/usePageTitle";

function Phone() {
  usePageTitle("Phone");

  return <PhoneNumber />;
}

export default Phone;
