import { useEffect, useRef } from "react";

import { StepContainer } from "./IdentityVerification.styles";
import incode from "incode";

interface RetryStepsProps {
  session: any;
  onSuccess: () => void;
  onError: (e?: any) => void;
  numberOfTries: number;
}

function RetrySteps({
  session,
  onSuccess,
  onError,
  numberOfTries,
}: RetryStepsProps) {
  const containerRef = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    if (incode) {
      incode.renderRetrySteps(
        containerRef.current,
        {
          token: session,
          numberOfTries,
          showPassport: false,
        },
        {
          onSuccess,
          onError,
        }
      );
    }
    
    isMounted.current = true;
  }, [onSuccess, onError, session, numberOfTries]);

  if (!incode) {
    return null;
  }

  return <StepContainer ref={containerRef}></StepContainer>;
}

export default RetrySteps;
