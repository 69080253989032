import { environment } from "./constants";

async function logOutTab(): Promise<any> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  xRooamSession && headers.append("x-rooam-session", xRooamSession);
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);

  try {
    const response = await fetch(`${environment.baseUrl}/logout`, {
      method: "POST",
      headers,
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Logout Failed", error);
    throw error;
  }
}

export default logOutTab;
